.fooimg {
  width: "30px" !important;
  height: "30px" !important;
}

@media (max-width: 380px) {
  .fooimg {
    width: "20px" !important;
    height: "20px" !important;
  }
}
