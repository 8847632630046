@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../../assets/Fonts/futuramediumbt.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../../assets/Fonts/futuralightbt.ttf") format("truetype");
  font-weight: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Kalam&family=Satisfy&display=swap");

.scrollable1 {
  /* background-color: red; */
  height: 100%;
  max-height: 550px;
  /* min-height: 90vh; */
  /* min-width: 300px; */
  overflow: auto;
  /* margin: 20px; */
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  /* padding: 0px; */
  /* scroll-snap-align: start; */
  /* scroll-padding-right: 80px; */
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.scrollable1::-webkit-scrollbar {
  display: none !important;
}
.scrollable1::-webkit-scrollbar {
  width: 0.4em !important;
}
::-webkit-scrollbar-track-piece {
  background: transparent;
}

.scrollable1::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.scrollable1::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0) !important;
  outline: 0px solid rgb(0, 0, 0) !important;
}

.QuotedText {
  /* font-family: Kalam, cursive; */
  font-family: Playfair Display;
  font-weight: 200;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}

.QuotedFooter {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-top: 20px;
  text-align: right;
  color: #4f4f4f;
}

.AllohaActiveButton5 {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
  outline: none !important ;
}

.AllohaActiveButton5:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.AllohaActiveButton5::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.AllohaActiveButton5::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: transform 0.3s ease;
}

.AllohaActiveButton5:hover::before {
  transform: scaleX(1);
  cursor: pointer;
  transform-origin: bottom left;
}

.UnActiveText5::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}
.UnActiveText5:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.UnActiveText5 {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
  border: none;
  /* font-size: 5rem; */
}
.UnActiveText5::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: transform 0.3s ease;
}

.UnActiveText5:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.ActiveButton2 {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Futura Medium BT;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
}

.ActiveButton2:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.ActiveButton2::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.ActiveButton2::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: transform 0.3s ease;
}
/* .UnActiveText2{
  font-size: 25px;
  width: auto;
  height: 80px;
  position: relative;
} */
.ActiveButton2:hover::before {
  transform: scaleX(1);
  cursor: pointer;
  transform-origin: bottom left;
}

.UnActiveText2:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}
.UnActiveText2 {
  height: 100px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
  background: rgb(219, 223, 225);
  border: none;
}
/* .UnActiveText2:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
} */

.AllohaActiveButton {
  height: 80px;
  width: auto;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
}

.AllohaActiveButton:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.AllohaActiveButton::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.AllohaActiveButton::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: transform 0.3s ease;
}

.AllohaActiveButton:hover::before {
  transform: scaleX(1);
  cursor: pointer;
  transform-origin: bottom left;
}

.UnActiveText::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}
.UnActiveText:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.UnActiveText {
  width: auto;
  height: 80px;
  position: relative;
  /* font-size: 5rem; */
}
.UnActiveText::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: transform 0.3s ease;
}

.UnActiveText:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}
.senseImgOne {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 550px;
  max-height: 540px;
  background-size: cover;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomhome 30s infinite alternate;
  background-image: url(./birds.png);
}

.senseImgTwo {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 550px;
  max-height: 540px;
  background-size: cover;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomhome 30s infinite alternate;
  background-image: url(./flowers.png);
}

.senseImgThree {
  width: 100%;
  height: 100%;
  min-height: 500px;
  position: relative;
  min-height: 550px;
  max-height: 540px;
  /* background-size: 100% 100%; */
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./sun.png);
}

.bg-zoom-ani {
  background-size: cover;
  animation: zoomhome2 5s ease alternate infinite;
}

* {
  /* margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s linear; */
  /* font-family: tahoma; */
}

html {
  /* scroll-behavior: smooth; */
}

body {
  /* overflow-x: hidden; */
}

.sec {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  /* color: #777;
  background: #f9f9f9; */
  letter-spacing: 2px;
}

.navbar {
  position: fixed;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 1000;
}

.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar ul li {
  width: 200px;
  position: relative;
  text-align: right;
}

.navbar ul li .dot {
  color: #fff;
  display: block;
  padding: 5px 0;
}

.navbar ul li .dot span {
  display: inline-block;
  background: #f44336;
  letter-spacing: 1px;
  padding: 10px 25px;
  margin-right: 30px;
  border-radius: 3px;
  transform: translateX(30px);
  opacity: 0;
}

.navbar ul li:hover .dot span {
  transform: translateX(0px);
  opacity: 1;
}

.navbar ul li .dot span::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(7px, -50%);
  border-left: 7px solid #f44336;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  display: block;
}

.navbar ul li .dot::before,
.navbar ul li .dot::after {
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 50%;
  display: block;
  transition: 0.2s ease-out;
}

.navbar ul li .dot::before {
  height: 5px;
  width: 5px;
  border: 2px solid #333;
  right: 0;
  transform: translateY(-50%);
}

.navbar ul li .dot.active::before,
.navbar ul li:hover .dot::before {
  border-color: #f44336;
  background: #f44336;
}
.navbar ul li .dot::after {
  height: 15px;
  width: 15px;
  border: 2px solid #f44336;
  right: -5px;
  transform: translateY(-50%) scale(0);
}

.navbar ul li .dot.active::after,
.navbar ul li:hover .dot::after {
  transform: translateY(-50%) scale(1);
}

@keyframes zoomhome2 {
  from {
    background-size: 100% 100%;
  }
  to {
    background-size: 110% 110%;
  }
}
@-webkit-keyframes zoomhome2 {
  from {
    background-size: 100% 100%;
  }
  to {
    background-size: 110% 110%;
  }
}

.senseImgFour {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 550px;
  max-height: 540px;
  background-size: 100% 100%;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./seenew.jpg);
  /* animation: zoomhome 30s infinite alternate; */
}

.senseImgFive {
  width: 100%;
  position: relative;

  height: 100%;
  min-height: 550px;
  max-height: 540px;
  background-size: 100% 100%;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./orange.png);
}
.noSenseImg {
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 550px;
  max-height: 540px;
  background-size: 100% 100%;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: red; */
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.noSenseImg h3 {
  text-align: center;
  color: black;
  font-size: 45px;
}
.noSenseImg img {
  background: black;
  margin-left: auto;
  margin-right: auto;
}
.noSenseImg .AllohaSenseIcon {
  text-align: center;
}
.senseImgOnePhone {
  width: 98%;
  height: 100%;
  position: relative;
  min-height: 345px;
  max-height: 345px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  /* animation: zoomhome 30s infinite alternate; */
  background-image: url(./birds.png);
}

.senseImgTwoPhone {
  width: 98%;
  height: 100%;
  position: relative;
  min-height: 345px;
  max-height: 345px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  /* animation: zoomhome 30s infinite alternate; */
  background-image: url(./flowers.png);
}

.senseImgThreePhone {
  width: 98%;
  height: 100%;
  /* min-height: 500px; */
  position: relative;
  min-height: 345px;
  max-height: 345px;
  /* background-size: 100% 100%; */
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./sun.png);
}
.senseImgFourPhone {
  width: 98%;
  height: 100%;
  position: relative;
  min-height: 345px;
  max-height: 345px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  /* animation: zoomhome 30s infinite alternate; */
  background-image: url(./seenew.jpg);
}

.senseImgFivePhone {
  width: 98%;
  height: 100%;
  position: relative;
  min-height: 345px;
  max-height: 345px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  /* animation: zoomhome 30s infinite alternate; */
  background-image: url(./orange.png);
}
.AllohaOverLayPhone {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 180.13px;
  height: 150px;
  /* bottom: 8%;
  right: 2%; */
  /* top: 28%; */
  top: 8%;
  left: 6%;
  padding: 6px;
  text-align: start;
  /* transform: translate(-0%, -50%); */
}
.AllohaOverLayPhone .AllohaSenseIconPhone {
  text-align: start;
  margin-top: 10px;
}
.AllohaOverLayPhone .AllohaSenseIconPhone img {
  width: 25px;
  height: 25px;
  align-items: left;
}
.AllohaOverLayPhone h3 {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 13px;
}
.AllohaOverLayPhone p {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 13px;
}
.AllohaOverLay .AllohaSenseIcon {
  text-align: start;
  margin-top: 25px;
}

.AllohaOverLay .AllohaSenseIcon img {
  width: 50px;
  height: 50px;
  align-items: left;
}

.AllohaOverLay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 260.13px;
  height: 220px;
  top: 10%;
  left: 3%;
  padding: 12px;
  text-align: start;
  /* font-family: Kalam, cursive; */
  font-family: Playfair Display;
  animation: AllohaOverLayani 2s 0s ease alternate;
}

@keyframes AllohaOverLayani {
  from {
    bottom: -60%;
    opacity: 0;
    visibility: hidden;
  }

  to {
    bottom: 8%;
    opacity: 1;
    visibility: visible;
  }
}

.AllohaOverLay h3 {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 23px;
}
.AllohaOverLay p {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  margin-top: 22px;
}
.line-break {
  color: rgb(194, 184, 184);
}
.img-zoom-ani {
  animation: zoomhome 30s infinite alternate;
}

.AllohaActiveButton {
  font-size: 25px;
}
.UnActiveText {
  font-size: 25px;
}
.UnActiveTextCaroOfAl {
  height: 30px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
  border: none;
}

.slick-btnAll .slick-active {
  /* background-color:red; */
  /* background: rgb(224, 223, 223); */
  opacity: 1;
  color: black;
}
.slick-btnAll .slick-active .UnActiveTextCaroOfAl {
  opacity: 1;
  /* color:red; */
}

.slick-btnAll.AllohaBtns {
  display: flex !important;
  gap: 3px;
  margin-block-start: auto !important;
  margin-block-end: auto !important;
  padding-inline-start: 0px !important;
  margin-left: auto;
  margin-right: auto;

  /* margin-top: 7px; */
}
.slick-btnAll.AllohaBtns li {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
/* h1::before {  
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  
  h1:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  h1::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    inset: 0 0 0 0;
    background: hsl(200 100% 80%);
    z-index: -1;
    transition: transform .3s ease;
  }
  
  h1 {
    position: relative;
    font-size: 5rem;
  } */
@media only screen and (max-width: 900px) {
  .QuotedText {
    font-size: 20px;
    line-height: 30px;
  }
  .AllohaActiveButton {
    font-size: 20px;
  }
  .AllohaUnActiveText {
    font-size: 20px;
  }
  /* .senseImgOne {
    width: 80%;
  height: 80%;
  position: relative;
  min-height: 240px;
  max-height: 6240px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomhome 30s infinite alternate;
  } */
  .scrollable1 {
    /* background-color: red; */
    height: 375px;
    min-width: 375px;
    overflow: auto;
    /* margin: 20px; */
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    padding: 0px;
    overflow-y: scroll;

    /* margin-top: "30px"; */
  }
  .senseImgOne {
    min-height: 375px;
    max-height: 375px;
  }
  .senseImgTwo {
    min-height: 375px;
    max-height: 375px;
  }
  .senseImgThree {
    min-height: 375px;
    max-height: 375px;
  }
  .senseImgFour {
    min-height: 375px;
    max-height: 375px;
  }
  .senseImgFive {
    min-height: 375px;
    max-height: 375px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1300px) {
  .QuotedText {
    font-size: 25px;
    line-height: 35px;
  }
  .scrollable1 {
    /* background-color: red; */
    height: 100%;
    max-height: 400px;
    min-width: 300px;
    overflow: auto;
    /* margin: 20px; */
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    padding: 0px;
    overflow-y: scroll;

    /* margin-top: "30px"; */
  }
}
@media (max-width: 767px) {
  .AllohaOverLay {
    width: 500.13px;
    height: 320px;
  }
}
@media (max-width: 600px) {
  .senseImgTwoPhone {
    min-height: 330px;
    max-height: 330px;
  }
  .senseImgOnePhone {
    min-height: 330px;
    max-height: 330px;
  }
  .senseImgThreePhone {
    min-height: 330px;
    max-height: 330px;
  }
  .senseImgFourPhone {
    min-height: 330px;
    max-height: 330px;
  }
  .senseImgFivePhone {
    min-height: 330px;
    max-height: 330px;
  }
  .scrollable1 {
    height: 100%;
    min-height: 330px;
    max-height: 330px;
    max-width: 330px;

    /* margin-top: "30px"; */
  }
}

@media (max-width: 480px) {
  .AllohaOverLay {
    top: 40%;
    width: 320px;
    height: 300px;
  }
  .AllohaOverLay h3 {
    font-family: "Playfair Display";
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 16px;
  }
  .AllohaOverLay p {
    font-family: "Playfair Display";
    font-weight: 400;
    font-size: 10px;
    color: #ffffff;
    margin-top: 16px;
  }
  .AllohaActiveButton {
    text-align: left;
  }
  .scrollable1 {
    height: 100%;
    min-height: 320px;
    max-height: 320px;
    max-width: 100%;

    /* margin-top: "30px"; */
  }
  .senseImgOne {
    min-height: 320px;
    max-height: 320px;
  }
  .senseImgTwo {
    min-height: 320px;
    max-height: 320px;
  }
  .senseImgThree {
    min-height: 320px;
    max-height: 320px;
  }
  .senseImgFour {
    min-height: 320px;
    max-height: 320px;
  }
  .senseImgFive {
    min-height: 320px;
    max-height: 320px;
  }
}
@media (max-width: 420px) {
  .AllohaOverLay {
    top: 40%;
    width: 260px;
    height: 260px;
  }
  .AllohaOverLay h3 {
    font-size: 10px;
    margin-bottom: 14px;
  }
  .AllohaOverLay p {
    font-size: 8px;
    margin-top: 14px;
  }
  .AllohaActiveButton {
    text-align: left;
  }
  .UnActiveTextCaroOfAl {
    font-size: 15px;
  }

  /* .scrollable1 {
    background-color: red;
    height: 100%;
    max-width: 325px;
    overflow: auto;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    padding: 20px;
    overflow-y: scroll;

    margin-top: "30px";
  } */

  /* .senseImgFive {
    width: 40%;
  }
  .senseImgFour {
    width: 40%;
  }
  .senseImgThree {
    width: 40%;
  }
  .senseImgTwo {
    width: 40%;
  }
  .senseImgOne {
    width: 40%;
  }
  .scrollable1 {
    width: 50%;
  } */
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
