@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Emblema+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap%27");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arizonia&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&display=swap");
@font-face {
  font-family: "Snell Roundhand";
  src: url("./assets/Fonts/snell.ttf");
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("./assets/Fonts/futuramediumbt.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Snell Roundhand";
  src: url("./assets/Fonts/snell.ttf");
}
@font-face {
  font-family: "Futura Italic BT";
  src: local("Futura Italic BT"),
    url("./assets/Fonts/Futura\ Medium\ Italic\ font.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Futura Italic md";
  src: local("Futura Italic md"),
    url("./assets/Fonts/Futura\ Book\ Italic\ font.ttf") format("truetype");
  font-weight: normal;
}
:root {
  margin: 0;
  font-family: "Futura Medium BT";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  font-family: "Playfair Display";
  scroll-behavior: smooth;
  width: 100%;
  padding: 0px;
  max-width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
}
::-webkit-scrollbar {
  display: none;
}
.loaderImg{
  width: 450px;
  height: auto;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input:focus {
  outline: 2px solid #000;
}
textarea:focus {
  outline: 2px solid #000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::placeholder {
  font-family: "Playfair Display";
}

input[type="text"] {
  font-family: Playfair Display;
}
textarea {
  font-family: Playfair Display;
}

input:focus {
  border: 1px solid black;
}

#ReactSimpleImageViewer {
  z-index: 100 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary.css-19nnow0-MuiInputBase-root-MuiInput-root:focus {
  border: 1px solid #000000;
}

.footerbgcolor {
  background-color: #f9f9f9;
  width: "100%";
  height: "100%";
  padding-top: "10px";
  padding-bottom: "10px";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hover-shadow {
  transition: box-shadow 0.3s ease-in-out;
}

.hover-shadow:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.img-filter {
  filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  filter: grayscale(0%);
}

.front-img-zoom {
  animation: scale-img 5s ease-out;
}
@keyframes scale-img {
  from {
    scale: 1.2;
  }
  to {
    scale: 1;
  }
}

.styles-module_close__2I1sI {
  top: 15px !important;
  right: 20px !important;
  opacity: 0.8 !important;
}

.styles-module_close__2I1sI:hover {
  opacity: 1 !important;
}

.slick-track {
  display: flex;
  place-items: center;
  align-items: center;
}

@media (max-width: 900px) {
  .front-img-RL {
    background: url(./assets/Images/mainLoader.jpg);
    background-size: inherit;
    background-position: left;
    animation: newimagestyle 10s infinite alternate;
  }

  .HomeLoaderMobile {
    background: url(./assets/Images/mainLoader.jpg);
    background-size: cover;
    background-position: center;
    animation: newimagestyle 15s infinite alternate;
    -webkit-animation: newimagestyle 15s infinite alternate;
  }
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12.MuiGrid-grid-xl-12.css-1vkfnbl-MuiGrid-root {
  margin-top: 50px;
}
@keyframes newimagestyle {
  from {
    background-position: right;
  }
  to {
    background-position: center;
  }
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 1px 1px 1px 1px !important;
}
.css-1vtqu8q-MuiTypography-root {
  padding-top: 0px !important;
}

.dropdown_item {
  background: rgb(255 255 255 / 75%) !important;
  padding-top: 0px !important;
  text-align: -webkit-left !important;
  padding-left: 30px !important;
}
.dropdown_item_two {
  margin-left: 10px;
  left: 193px !important;
}
.dropdown_menu_home {
  padding-inline-start: 0px !important;
}
.dropdown_item_three {
  left: 202px !important;
}

.dropdown_item {
  text-align: center !important;
  padding-left: 0px !important;
}

.Dropdowntextheader {
  text-align: center !important;
}
.dropdown_menu_home {
  margin-left: 0px !;
}

.whatsappicon {
  width: 40px;
  position: fixed;
  top: 92%;
  left: 02%;
  z-index: 10;
}
.dropdown_menu_home {
  margin-left: 0px !important;
}
audio {
  width: 100px;
  height: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
}

audio::-webkit-media-controls-mute-button {
  display: none !important;
  background: transparent !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
  background: transparent !important;
}

audio::-webkit-media-controls-volume-control-container.closed {
  display: none !important;
  background: transparent !important;
}
audio::-webkit-media-controls-volume-control-container {
  display: none !important;
}

@media (max-width:450px) {
  .loaderImg{
    width: 350px;
    height: auto;
  }
}
@media (max-width:350px) {
  .loaderImg{
    width: 320px;
    height: auto;
  }
}