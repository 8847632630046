@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../../assets/Fonts/futuramediumbt.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../../assets/Fonts/futuralightbt.ttf") format("truetype");
  font-weight: normal;
}

.ActiveButton {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
}

.UnActiveText:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}
.UnActiveText {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;

  border: none;
}
/* @media only screen and (max-width: 600px) {
  .UnActiveText {
    text-align: left;
  }
} */

@media (max-width: 480px) {
  .ActiveButton {
    text-align: center !important;
    align-items: center !important;
  }
  .UnActiveText {
    text-align: center !important;
    align-items: center !important;
  }
}
