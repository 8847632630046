@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../../assets/Fonts/futuramediumbt.ttf") format("truetype");
  font-weight: normal;
}
.about-us-bg-image {
  width: 100%;
  height: 400px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../../assets/Images/AboutUsImages/thecompany.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.HistorytextBox {
  height: 520px;
  font-size: 12px;
  line-height: 4px;
}

.messageStyle {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #333334;
  text-align: Justify;
  margin-bottom: 0px;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.messageStyle1 {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  text-align: Justify;
  margin-bottom: 7px;
  animation-duration: 1s;
  animation-fill-mode: both;
  padding: 10px;
  margin-top: -50px;
}
.HistorySpan {
  font-family: "Playfair Display";
  font-weight: 600;
  color: black;
  font-size: 42px;
}

.textStyleOfChairMainMsg {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
  text-align: Justify;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.MainTextStyl {
  font-family: Playfair Display;
  font-size: 42px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 1;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  text-align: left;
  margin-top: 10px;
}
.titleStyle {
  font-family: "Futura Md BT";
  font-size: 28px;
  margin-top: 16px;
}

.visionmissiontext {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #979797;
  text-align: Justify;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.textStyleOfChairMainMsg,
.textStyleOfChairMainMsg span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.textStyleOfChairMainMsg span {
  display: inline-block;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
.img-mission {
  background-color: "red";
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
.HistorySpan {
  font-weight: 600;
  color: black;
  font-size: 42px;
}
.AboutHerosectionBox {
  width: 100%;
  height: 530px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
@media (max-width: 768px) {
  .textStyleOfChairMainMsg {
    font-size: 16px;
    line-height: 25px;
  }
  .visionmissiontext {
    font-size: 16px;
    line-height: 25px;
  }
}

.imgSize {
  height: 558px;
}
.OurHistoryTextStyle {
  line-height: 25px;
}

@media (max-width: 1400px) {
  .imgSize {
    height: 100%;
    width: 95%;
  }
}
@media (max-width: 1360px) {
  .imgSize {
    height: 600px;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .HistorytextBox {
    height: 520px;
    font-size: 12px;
    line-height: 4px;
    overflow: auto;
  }

  .imgSize {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .imgSize {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 380px) {
  .HistorySpan {
    margin-top: 25px;
  }
}
