.MobileheroSectionMedhanshLogo {
  width: 40%;
  height: auto;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MobileheroSectionAlloaLogo {
  width: 20%;
  height: auto;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MobileheroSectionInverLogo {
  width: 40%;
  height: auto;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
  .MobileheroSectionAlloaLogo {
    width: 40%;
    top: 70%;
    left: 45%;
    transform: translate(-50%, -50%);
  }
  .MobileheroSectionMedhanshLogo {
    width: 40%;
    top: 70%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
  .MobileheroSectionInverLogo {
    width: 40%;
    top: 80%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 900px) {
  .MobileheroSectionAlloaLogo {
    width: 40%;
    top: 70%;
    left: 45%;
    transform: translate(-50%, -50%);
  }
  .MobileheroSectionMedhanshLogo {
    width: 20%;
    top: 70%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
  .MobileheroSectionInverLogo {
    width: 40%;
    top: 80%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
}
