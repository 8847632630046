.dropdown {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  opacity: 1;
  position: relative;
  font-size: 18px;
  background: transparent;
  z-index: 100;
  width: 50% !important;
  max-width: 300px !important;
}
.dropdown:hover {
  cursor: pointer;
}

.dropdown_menu {
  width: auto;
  position: absolute;
  /* top: 76%; */
  left: -120%;
  width: 100%;
} 
.dropdown_menu li {
  background: rgba(249, 249, 249, 0.3);
  padding: 10px 0px;
  width: 200px;
  font-size: 16px;
}
.dropdown_menu li > p {
  color: #dedbdb;
}
.dropdown_menu li:hover > p {
  color: #fff;
}

.dropdown:hover .dropdown_menu {
  opacity: 1;
  top: 76%;
  visibility: visible;
}

.dropdown_item:hover .dropdown_menu_hover_two {
  opacity: 1;
  visibility: visible;
}


.dropdown_menu_home {
  width: auto;
  position: absolute;
  left: -120% !important;
  width: 200px;
  max-width: 250px;
  text-align: center;
}

.dropdown_item_two {
  width: auto;
  position: absolute;
  left: 235px;
  right: 0%;
  top: 100%;
  width: 220px;
  padding: 0;
  opacity: 0;
  transition: all 1.5s ease;
}

.dropdown_item_three {
  width: auto;
  position: absolute;
  left: 240px;
  right: 0%;
  top: 200%;
  width: 200px;
  padding: 0;
  opacity: 0;
  transition: all 1.5s ease;
}

.dropdown_menu_home_three {
  width: auto;
  position: absolute;
  left: -120%;
  width: 100%;
}

.dropdown_menu_home li {
  background: rgba(249, 249, 249, 0.3);
  padding: 10px 0px;
  width: 200px;
  font-size: 16px;
}

.dropdown_menu_home_three li {
  background: rgba(249, 249, 249, 0.3);
  padding: 10px 0px;
  width: 200px;
  font-size: 16px;
}

.dropdown_menu_home_two li {
  background: rgba(249, 249, 249, 0.3);
  padding: 10px 0px;
  width: 200px;
  font-size: 16px;
}

.dropdown_menu_home li > p {
  color: #f9f9f3;
  opacity: 0.7;
}

.dropdown_menu_home_two li > p {
  color: #f9f9f3;
  opacity: 0.7;
}

.dropdown_menu_home_three li > p {
  color: #f9f9f3;
  opacity: 0.7;
}
.dropdown_menu_home li:hover > p {
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.dropdown_menu_home_two li:hover > p {
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.dropdown_menu_home_three li:hover > p {
  color: #fff;
  opacity: 1;
  font-size: 15px;
}
.dropdown:hover .dropdown_menu_home {
  opacity: 1;
  top: 76%;
  visibility: visible;
}

.DropdownOne:hover .dropdown_item_two {
  opacity: 1;
  top: 0%;
  visibility: visible;
  transition: all 1.5s ease;
}

.DropdownTwo:hover .dropdown_item_three {
  opacity: 1;
  top: 0%;
  visibility: visible;
  transition: all 1.5s ease;
}

.dropdown_menu_home {
  transition: all 600ms ease;
  top: 90%;
  opacity: 0;
  visibility: hidden;
}

.dropdown_menu_hover_two {
  transition: all 1s ease;
  top: 90%;
  opacity: 0;
  visibility: hidden;
}

.dropdown_menu_hover_three {
  transition: all 1s ease;
  top: 90%;
  opacity: 0;
  visibility: hidden;
}

.NavbarStyle {
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 1.09%;
  background: #fff;
  border-radius: 0px;
  height: 80px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  transition: all 1.5s ease;
  opacity: 0;
  visibility: hidden;
}

.NewNavbarStyle {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 1.09%;
  background: transparent;
  border-radius: 0px;
  height: 80px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
  visibility: visible;
  border-bottom: 0.5px solid rgb(175, 175, 175);
}

.NewNavbarStyleUnActive {
  opacity: 0;
  visibility: hidden;
}

.NavbarStyleActive {
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 1.09%;
  background: #fff;
  border-radius: 0px;
  height: 80px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s ease;
  opacity: 1;
  visibility: visible;
}

@keyframes slidenav {
  from {
    top: -10%;
  }
  to {
    top: 0%;
  }
}

li {
  list-style: none;
}

@media screen and (max-width: 899px) {
  .NavbarStyle {
    display: none;
  }
  .NavbarStyleActive {
    display: none;
  }
  .NewNavbarStyle {
    display: none;
  }
  .NewNavbarStyleUnActive {
    display: none;
  }
}

.newDropDownText {
  font-family: "Playfair Display";
  font-style: "normal";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: "center";
  color: rgb(218, 216, 216);
  background-color: transparent;
  border: none;
}

.Dropdowntextheader {
  font-family: Playfair Display;
  font-style: "normal";
  font-weight: 400;
  font-size: "14px";
  line-height: "20px";
  text-align: "center";
  color: #000;
  background-color: #fff;
  border: none;
  opacity: 0.8;
}
.Dropdowntextheader:hover {
  color: #000;
  font-weight: 600;
  cursor: pointer;
  font-size: "16px";
  opacity: 1;
}

.newDropDownText:hover {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: "16px";
}
