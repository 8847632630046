/* .OneImg {
  width: 98%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills1.png);
  transform: scale(1.0);
  overflow: hidden;
} */
/* .OneImg img {
  width:100%;
  transition: all 20s linear;
} */
/* .OneImg:hover {
  animation: zoomhome 20s linear;
  transform: scale(1.5);
  cursor: pointer;
} */
.TwoImg {
  width: 98%;
  margin-top: 5px;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;

  background-repeat: no-repeat;
  /* background-image: url("../../assets/Images/AllohaHills/g/m\ \(1\).j"); */
  /* animation: zoomhome 20s linear; */
}

.ThreeImg {
  width: 100%;
  margin-top: 5px;
  max-width: 98%;
  overflow: hidden;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills3.png);
}

.FourImg {
  width: 97%;
  margin-top: 5px;
  overflow: hidden;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills4.png);
}

.FiveImg {
  width: 98%;

  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills5.png);
}

.SixImg {
  width: 98%;

  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills6.png);
}

.SevenImg {
  width: 98%;

  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills7.png);
}

.EightImg {
  width: 98%;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills8.png);
}

/* .OneImg:hover {
  transition: transform 0.5s;
  animation: zoomhome 20s ease-in-out;
} */
/* 
.TwoImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}


.ThreeImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.FourImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.FiveImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.SixImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.SevenImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.EightImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
} */
.img-filter {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  -webkit-filter: grayscale(0%);

  filter: grayscale(0%);
}

.img-zoom:hover {
  background-size: 110% 110%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}

.AlOneImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/AllohaHills/g/m (1).jpg");
}

.AlTwoImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/AllohaHills/g/m (2).jpg");
}

.AlThreeImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/AllohaHills/g/m (3).jpg");
}

.AlFourImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/AllohaHills/g/m (4).jpg");
}

.AlFiveImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/AllohaHills/g/m_5.jpg");
}

.AlSixImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/AllohaHills/g/m (6).jpg");
}

.AlSevenImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/AllohaHills/g/m (7).jpg");
}

.AlEightImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/AllohaHills/g/m (8).jpg");
}

.AlNineImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/AllohaHills/g/m (9).jpg");
}
.AlTenImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills2.png);
}
.AlElevenImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills3.png);
}
.AlTweleveImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills4.png);
}

@-webkit-keyframes zoomhome {
  from {
    -webkit-transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.1, 1.1);
  }
}

@keyframes zoomhome {
  from {
    transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.1, 1.1);
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .AlOneImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlTwoImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlThreeImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlFourImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlFiveImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlSixImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlSevenImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlEightImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlNineImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlTenImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlElevenImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlTweleveImg {
    min-height: 280px;
    max-height: 280px;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .AlOneImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlTwoImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlThreeImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlFourImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlFiveImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlSixImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlSevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlEightImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlNineImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlTenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlElevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlTweleveImg {
    min-height: 250px;
    max-height: 250px;
  }
}
