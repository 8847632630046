@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../assets/Fonts/futuramediumbt.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../assets/Fonts/futuralightbt.ttf") format("truetype");
  font-weight: normal;
}

.card-container {
  cursor: pointer;
  height: 400px;
  width: 313px;
  perspective: 600;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
/*card to fit the size of the card container by giving the width and height of 100% and position of absolute which is relative to the card container*/
.card {
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  position: absolute;
  transition: all 1s ease-in-out;
}
/*card rotate from y axis on hover*/
.card:hover {
  transform: rotatey(180deg);
}
/*Front side of the card with the property of backface visibility to make the other side of card hidden*/
.side {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 6px;
  background-color: white;
}
/*back side of the card*/
.back {
  transform: rotatey(180deg);
  background-color: white;
}

/* cards css end */
.ourProjectText {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #908186;
  text-align: center;
  margin-bottom: 20px;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.AboutUs-Background {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/Images/HomeImages/Asset.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  /* position: relative; */
  background-size: cover;
  background-position: center;
  min-height: 450px;
  /* background-color: red; */
  max-width: auto;
  overflow: hidden;
  margin-top: "10px";
  /* position: relative; */
}

.AboutHomeText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #808186;
  text-align: Justify;
  /* margin-bottom: 20px; */
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ModalTextHome {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #808186;
  text-align: Justify;
  /* margin-bottom: 20px; */
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.OurHistoryTextStyle {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
  color: #808186;
  text-align: Justify;
  /* margin-bottom: 20px; */
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.CLientSayText {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #808186;
  text-align: center;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.OurPartnerText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: center;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.AmentiseText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #bababa;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.visionmissiontext {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #979797;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.HeroText,
.HeroText span {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.OurPartnerText,
.OurPartnerText span {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.OurPartnerText span {
  display: inline-block;
}

.HeroText span {
  display: inline-block;
}

.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
  animation-name: fadeInBottom;
}

.LinkStyleText {
  font-family: Futura Medium BT;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #5392bf;
  text-align: Justify;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  text-align: center;
  align-items: flex-end;
}
.indicators > button {
  width: 12px;
  border: 0px;
  border-radius: 50%;
  height: 12px !important;
}

@-webkit-keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .visionmissiontext {
    font-size: 16px;
    line-height: 25px;
  }
  .OurPartnerText {
    font-size: 14px;
    line-height: 25px;
  }
  .AboutHomeText {
    line-height: 20px;
  }
  .ModalTextHome {
    font-size: 14px;
    line-height: 20px;
  }
}

.CaroDivOne {
  max-width: 100vw;
  width: 100vw;
  height: 105vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(../../assets/Images/HomeImages/18_Garden_Sectoe_A_new.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-y: hidden;
  z-index: 0;
  -webkit-animation: zoomhome 25s infinite alternate;
  animation: zoomhome 25s infinite alternate;
}

.HeroText {
  font-family: Futura Medium BT;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #f3f3f3;
  text-align: right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  word-wrap: break-word;
  /* display: "flex" */
  white-space: pre-wrap;
  margin-top: 30px;
  -webkit-animation: fadeInBottom 1s ease-in-out;
  animation: fadeInBottom 1s ease-in-out;
}

.CaroDivTwo {
  max-width: 100vw;
  width: 100vw;
  height: 105vh;
  background: url(../../assets/Images/HomeCarouselImages/cam_03_FFF-min.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-animation: zoomhome 25s infinite alternate;
  animation: zoomhome 25s infinite alternate;
}
.CaroDivThreePhone {
  max-width: 100vw;
  width: 100vw;
  height: 100%;
  min-height: 50vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(../../assets/Images/HomeCarouselImages/Inverness.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-y: hidden;
  z-index: 0;
  /* animation: zoomhome 25s infinite alternate; */
}
.CaroDivTwoPhone {
  max-width: 100vw;
  width: 100vw;
  height: 100%;
  min-height: 50vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(../../assets/Images/HomeCarouselImages/cam_03_FFF-min.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-y: hidden;
  z-index: 0;
  /* animation: zoomhome 25s infinite alternate; */
}

.CaroDivOnePhone {
  max-width: 100vw;
  width: 100vw;
  height: 100%;
  min-height: 50vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(../../assets/Images/HomeImages/18_Garden_Sectoe_A_new.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-y: hidden;
  z-index: 0;
  /* animation: zoomhome 25s infinite alternate; */
}
.CaroDivThree {
  max-width: 100vw;
  width: 100vw;
  z-index: 0;
  height: 105vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(../../assets/Images/HomeCarouselImages/Inverness.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  -webkit-animation: zoomhome 25s infinite alternate;
  animation: zoomhome 25s infinite alternate;
}

@-webkit-keyframes imageSyle {
  0% {
    opacity: 1;
    background-size: 100%;
  }
  100% {
    opacity: 1;
    background-size: 110%;
  }
}

@keyframes imageSyle {
  0% {
    opacity: 1;
    background-size: 100%;
  }
  100% {
    opacity: 1;
    background-size: 110%;
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@-webkit-keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes smoothScroll {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

.CLientSayText {
  /* font-family: Futura Medium BT; */
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: "22px";
  text-align: "center";
  color: "#808186";
  margin-top: 20px;
  margin-bottom: 20px;
  /* white-space: "pre-wrap"; */
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* .AboutSectionOfHome {
  height: 100%;
  min-height: 600px;
  max-height: 900px;
  width: 100%;
  background-color: red;
} */

.PreviouProjectDivOne {
  background: url(../../assets/Images/HomeImages/1_Club_house_Secor_A_new.jpg);
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 375px;
  /* background: url(../../assets/Images/HomeImages/6_Sample_House_new.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivTwo {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 375px;
  background: url(../../assets/Images/HomeImages/6_Sample_House_new.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivThree {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/3_Garden_Sector_A_new.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivFour {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/12_Sample_House_new.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivFive {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/21_Club_house_Secor_A_new.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivSix {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/19_Club_house_Secor_A_new.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivSeven {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/caraimg.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivEight {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/caraeight.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivNine {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/14_Wood_house_SectorB.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}
/* .PreviouProjectDivSeven {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 500px;
  max-height: 600px;
  background: url(../../assets/Images/HomeImages/21_Club_house_Secor_A.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  animation: zoomhome 20s ease-in-out;
}
*/
.PreviouProjectDivEight {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/22_Sample_house.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}

/* .PreviouProjectDivNine {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/14_Wood_house_SectorB.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  animation: zoomhome 20s ease-in-out;
} */
.HeroSidebarButtonWrapOne {
  width: 100%;
  max-width: 50px;
  height: 100%;
  /* max-height: 500px; */
  position: absolute;
  top: 0;
  border: none;
  left: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
}

.HeroSidebarButtonWrapTwo {
  width: 100%;
  max-width: 50px;
  height: 100%;
  /* max-height: 500px; */
  position: absolute;
  top: 0;
  border: none;
  right: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
}

.PrevSidebarButtonWrapOne {
  width: 100%;
  max-width: 50px;
  height: 100%;
  max-height: 88vh;
  position: absolute;
  top: 0;
  border: none;
  left: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
}

.PrevSidebarButtonWrapTwo {
  width: 100%;
  max-width: 50px;
  height: 100%;
  max-height: 88vh;
  position: absolute;
  top: 0;
  border: none;
  right: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
}

.HeroSidebarButtonOne {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  background: linear-gradient(
    90deg,
    rgba(19, 18, 18, 0.8) 0%,
    rgba(77, 77, 77, 0) 100%
  );
  opacity: 0.5;
  background: transparent;

  transition: all 1.5s ease-in-out;
}

.PrevSidebarButtonOne {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  background: linear-gradient(
    90deg,
    rgba(19, 18, 18, 0.8) 0%,
    rgba(77, 77, 77, 0) 100%
  );
  opacity: 0.5;
  background: transparent;

  transition: all 1.5s ease-in-out;
}

.HeroSidebarButtonWrapOne:hover .HeroSidebarButtonOne {
  display: block;
  opacity: 1;
}

.HeroSidebarButtonTwo {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  opacity: 0.5;

  /* background: linear-gradient(
    -90deg,
    rgba(19, 18, 18, 0.8) 0%,
    rgba(249, 249, 249, 0) 100%
  ); */
  background: transparent;
  transition: all 1.5s ease-in-out;
}

.PrevSidebarButtonTwo {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  opacity: 0.5;

  /* background: linear-gradient(
    -90deg,
    rgba(19, 18, 18, 0.8) 0%,
    rgba(249, 249, 249, 0) 100%
  ); */
  background: transparent;
  transition: all 1.5s ease-in-out;
}

.HeroSidebarButtonWrapTwo:hover .HeroSidebarButtonTwo {
  opacity: gray;
}

.PrevSidebarButtonWrapOne:hover .HeroSidebarButtonOne {
  opacity: gray;
}
/* new style */

.main-slider {
  /* background-color: red; */
  position: relative;
}
.main-slider .content {
  position: relative;
}
.main-slider .slide {
  position: relative;
  padding: 395px 0px 230px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.main-slider .slide:before {
  position: absolute;
  content: "";
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 9;
}
.main-slider h1 {
  position: relative;
  color: #ffffff;
  font-size: 35px;
  font-weight: bold;
  /*font-weight: 600;*/
  line-height: 32px;
  letter-spacing: 5px;
  margin-bottom: 30px;
  text-transform: uppercase;
  opacity: 0;
  transition: all 1000ms ease;
  transform: translateY(50px);
}
.main-slider .active h1 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1300ms;
}
.main-slider h5 {
  position: relative;
  color: #777;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-block;
  text-transform: uppercase;
  opacity: 0;
  transition: all 1000ms ease;
  transform: translateY(50px);
}
.main-slider .active h5 {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 1000ms;
}
.main-slider .link-box {
  opacity: 0;
  transition: all 1000ms ease;
  transform: translateY(50px);
}
.main-slider .link-box .theme-btn {
  color: #fff;
  border-color: #fff;
}
.main-slider .active .link-box {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 2500ms;
}
.main-slider .content .title {
  position: relative;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;
  margin-bottom: 25px;
  opacity: 0;
  transition: all 1000ms ease;
  transform: translateY(50px);
}
.main-slider .content .title:after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0px;
  width: 70px;
  height: 1px;
  margin-left: -35px;
  background-color: #ffffff;
}
.main-slider .active .title {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 600ms;
}
.main-slider .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 51px;
}
.main-slider .owl-dots .owl-dot {
  display: none;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0px 4px;
  /*display: inline-block;*/
  border: 4px solid rgba(255, 255, 255, 0.2);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-slider .owl-dots .owl-dot:hover,
.main-slider .owl-dots .owl-dot.active {
  border-color: rgba(255, 255, 255, 0.6);
}
.main-slider .owl-nav {
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 1;
  width: 100%;
  margin-top: -20px;
  opacity: 0;
  transition: 0.5s ease;
}
.main-slider:hover .owl-nav {
  opacity: 1;
}
.main-slider .owl-nav .owl-prev {
  position: absolute;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  opacity: 0.3;
  left: 25px;
  /*width: 78px;
    height: 78px;*/
  /*color: rgba(255, 255, 255, 0.5);
  /*line-height: 62px;*/
  text-align: center;
  font-size: 21px;
  /*border: 8px solid rgba(255, 255, 255, 0.30);*/
  display: inline-block;
  /*background-color: rgba(255, 255, 255, 0.10);*/
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-slider .owl-nav .owl-next {
  position: absolute;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  opacity: 0.3;
  right: 25px;
  /* width: 78px;
  height: 78px;*/
  /*color: rgba(255, 255, 255, 0.50);
  line-height: 62px;*/
  text-align: center;
  font-size: 21px;
  /* border: 8px solid rgba(255, 255, 255, 0.30);*/
  display: inline-block;
  /*background-color: rgba(255, 255, 255, 0.10);*/
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-slider .owl-nav .owl-prev:hover,
.main-slider .owl-nav .owl-next:hover {
  color: #fff;
  border-color: #fff;
  opacity: 0.8;
}
.main-slider .left-side {
  position: absolute;
  left: 120px;
  top: 0;
  width: 110px;
  height: 100%;
  padding: 60px 0;
  background: #f58634;
  z-index: 9;
}
.main-slider .left-side h4 {
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s ease;
  transform: rotate(-90deg) translateX(-100%) translateY(-54%);
  transform-origin: 0 0;
  left: 50%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  letter-spacing: 30px;
  opacity: 0;
  transition: 0.7s ease;
}
.main-slider .left-side h4.now-in-view {
  opacity: 1;
  letter-spacing: 7px;
}
.main-slider .left-side h4:before {
  position: absolute;
  content: "";
  background: #fff;
  height: 1px;
  width: 60px;
  left: -80px;
  top: 12px;
  transform: translate(-10px);
}
.main-slider .left-side .social-icon {
  text-align: center;
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
}
.main-slider .left-side .social-icon li {
  margin-bottom: 20px;
}
.main-slider .left-side .social-icon li:last-child {
  margin-bottom: 0px;
}
.main-slider .left-side .social-icon li a {
  color: #fff;
  font-size: 22px;
  transition: 0.5s ease;
}
.main-slider .left-side .social-icon li a:hover {
  color: #222;
  transform: rotate(-360deg) scale(2);
  display: inline-block;
}
/* Main slider style two */
.main-slider.style-two {
  padding-left: 230px;
}
.main-slider.style-two:before {
  position: absolute;
  content: "";
  background: #222;
  left: 0;
  top: 0;
  width: 120px;
  height: 90%;
}
.main-slider.style-two .auto-container {
  max-width: 1430px;
}
.main-slider.style-two .slide {
  padding-top: 250px;
  padding-bottom: 240px;
}
.main-slider.style-two .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 51px;
  max-width: 1400px;
  width: 100%;
  transform: translateX(-50%);
  padding: 0 10px;
}
.main-slider.style-two .owl-nav .owl-prev {
  left: auto;
  right: 120px;
  top: -44px;
}
.main-slider.style-two .owl-nav .owl-next {
  top: 44px;
}

/* Card flip css */

.flip-card {
  background-color: transparent;
  width: 400px;
  height: 400px;
  perspective: 1000px;
  margin-left: "auto";
  margin-right: auto;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1.5s;
  transform-style: preserve-3d;
  background-color: #fff;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  /* background-color: rgba(233, 233, 233, 0.46); */
  background-color: #fff;
  color: white;
  transform: rotateY(180deg);
}

@media (min-width: 481px) and (max-width: 900px) {
  .PreviouProjectDivOne {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivTwo {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivThree {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivFour {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivFive {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivSix {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivSeven {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivEight {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivNine {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }

  .PrevSidebarButtonWrapTwo {
    max-height: 49vh;
  }
  .PrevSidebarButtonWrapOne {
    max-height: 49vh;
  }
}
@media (max-width: 480px) {
  .PreviouProjectDivOne {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivTwo {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivThree {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivFour {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivFive {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivSix {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivSeven {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivEight {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivNine {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PrevSidebarButtonWrapTwo {
    max-height: 49vh;
  }
  .PrevSidebarButtonWrapOne {
    max-height: 49vh;
  }

  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1.5s;
    transform-style: preserve-3d;
    background-color: #fff;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

@media (max-width: 480px) {
  .card-container {
    cursor: pointer;
    height: 320px;
    width: 270px;
    perspective: 600;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  /*card to fit the size of the card container by giving the width and height of 100% and position of absolute which is relative to the card container*/
  .card {
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    position: absolute;
    transition: all 1s ease-in-out;
  }
  /*card rotate from y axis on hover*/
  .card:hover {
    transform: rotatey(180deg);
  }
  /*Front side of the card with the property of backface visibility to make the other side of card hidden*/
  .side {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 6px;
    background-color: white;
  }
  /*back side of the card*/
  .back {
    transform: rotatey(180deg);
    background-color: white;
  }
}
