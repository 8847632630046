.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  background: #fcfcfc;
  width: 47px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.carousel .slick-prev {
  left: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.carousel .slick-next {
  right: 0;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}

.custom-indicatornew {
  position: absolute;
  top: 86%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  /* height: 110px; */
  /* filter: grayscale(100%); */
}

.InformationBoxThree {
  margin-top: -420px;
  margin-left: auto;
  position: relative;
  margin-right: 50px;
  width: 100%;
  max-width: 400px;
  text-align: right;
  height: 100%;
  opacity: 1;
  text-align: center;
  z-index: 10;
}

.custom-indicatornewHome {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  min-height: 250px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  min-height: 5px;
  max-height: 5px;
  cursor: pointer;
}
.custom-indicatornew li {
  height: 100%;
  width: 75%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  /* border-top: 3px solid #FFFFFF; */
  padding-top: 1;
  padding-left: auto;
  padding-right: auto;
  border: none;
  cursor: pointer;

  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  background-color: transparent;
}

.custom-indicatornew li.slick-active {
  /* filter: grayscale(0%); */
  border: none;
  background-color: transparent;
  height: 50px;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.multi__image:hover {
  transform: scale(1.1);
  transition: 500ms;
}

.slick-dotnewnew li button {
  height: 0px;
  width: 100%;
  max-width: 250px;
  border: none;
  /* border-top: 3px solid rgb(212, 212, 212); */
  background-color: rgb(213, 205, 205);
  color: rgb(213, 205, 205);
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-top: 25%;
  /* font-size: 15px; */
}

.slick-dotnewnew li.slick-active button {
  height: 0px;
  width: 100%;
  max-width: 200px;
  border: none;
  /* border-top: 3px solid rgb(12, 8, 9); */
  background-color: rgb(92, 88, 88);
  color: rgb(92, 88, 88);
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  /* font-size: 15px; */
}

.slick-dotnew li.slick-active button {
  height: 50px;
  width: 100%;
  max-width: 200px;
  border-top: 3px solid #fff;
  background-color: transparent;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
}

.slick-dotnew li button {
  height: 50px;
  width: 90%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background-color: transparent;
  color: white;
  border-top: 3px solid rgb(255, 255, 255);
  cursor: pointer;
  color: gray;
  font-size: 15px;
}
.slick-dotnew li button:hover {
  border-top: 3px solid #fff;
  background-color: transparent;
  color: #fff;
}
.slick-dotnew.slick-thumb {
  margin-top: 50px;
}
.slick-dotnew {
  /* bottom: -114px; */
}

/* new */

.slick-dotnewnew1 li button {
  height: 0px;
  width: 100%;
  max-width: 250px;
  border: none;
  /* border-top: 3px solid rgb(212, 212, 212); */
  background-color: rgb(213, 205, 205);
  color: rgb(213, 205, 205);
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  /* font-size: 15px; */
}

.slick-dotnewnew1 li.slick-active button {
  height: 0px;
  width: 100%;
  max-width: 200px;
  border: none;
  /* border-top: 3px solid rgb(12, 8, 9); */
  /* background-color: rgb(92, 88, 88); */
  /* color: rgb(92, 88, 88); */
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  /* font-size: 15px; */
}

.slick-dotnew1 li.slick-active button {
  height: 50px;
  width: 100%;
  max-width: 200px;
  /* border-top: 3px solid #fff; */
  background-color: transparent;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
}

.slick-dotnew1 li button {
  height: 50px;
  width: 90%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background-color: transparent;
  color: white;
  /* border-top: 3px solid gray; */
  cursor: pointer;
  color: gray;
  font-size: 15px;
}
.slick-dotnew1 li button:hover {
  /* border-top: 3px solid #fff; */
  background-color: transparent;
  color: #fff;
}
.slick-dotnew.slick-thumb {
  margin-top: 50px;
}
/* .slick-dotnew {
  bottom: -114px;
} */

@media (max-width: 600px) {
  .custom-indicatornew {
    top: 100%;
  }
  .custom-indicatornewHome {
    min-width: 220px;
    max-width: 220px;
  }
}
@media (max-width: 480px) {
  .custom-indicatornewHome {
    min-width: 220px;
    max-width: 220px;
  }
}

/* @media (min-width: 900px) and (max-width: 980px) {
  .audiomusic{
    margin-left:5px;
  }
} */

audio {
  width: 100px;
  height: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
  /* background: red !important; */
}

audio::-webkit-media-controls-mute-button {
  display: none !important;
  background: transparent !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
  background: transparent !important;
}

audio::-webkit-media-controls-volume-control-container.closed {
  display: none !important;
  background: transparent !important;
}
audio::-webkit-media-controls-volume-control-container {
  display: none !important;
}
