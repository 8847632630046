
@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("./assets/Fonts/futuramediumbt.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: Futura Medium BT, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.img-zoom-ani {
  animation: zoomhome 30s infinite alternate;
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

.img-filter {
  filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  filter: grayscale(0%);
}

.img-zoom:hover {
  background-size: 110% 110%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}
