.AmentiseOne {
  background-color: #ffffff;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  height: 180px;
  width: 99%;
  position: relative;
  display: grid;
  place-items: center;
}
.AmentiseOneHover {
  position: absolute;
  transition: all 0.7s ease;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  overflow: hidden;
  width: 100%;
  display: grid;
  place-items: center;
}

.ssImageSectionStyles {
  background-image: url(../../assets/Images/AllohaHills/BroadImg.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  min-height: 590px;
  position: relative;
  -webkit-background-size: 2650px 1440px;
}
@media (max-width: 480px) {
  .ImageSectionStyles {
    background-image: url(../../assets/Images/AllohaHills/BroadImg.png);
    margin-top: -100vh;
    width: 100vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    top: 0;
    height: 200px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 0;
  }
}

.parallax-section {
  position: relative;
  width: 100%;
  height: 700px;
}
.parallax-child-section {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
}

#frontlashID {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  will-change: transform;
  z-index: 1;
}
.fw-main-row {
  background-attachment: scroll;
  background-image: url(../../assets/Images/AllohaHills/BroadImg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.am-img {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  opacity: 1;
  scale: 100%;
}

.AmentiseOne:hover .am-img {
  opacity: 0;
  scale: 0%;
}
.AmentiseOne:hover p {
  opacity: 1;
  scale: 100%;
}

.AmentiseOne p {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  opacity: 0;
  scale: 0%;
}

.HerosectionButtonStyle {
  width: 100%;
  max-width: 800px;
  position: absolute;
  top: 80%;
  right: 0%;
  background: transparent;
  height: auto;
  border: none;
}

.hoverImgs {
  width: 100%;
  overflow: hidden;
}

.hoverImgs img {
  width: 100%;
  transition: all 20s linear;
}

.hoverImgs img:hover {
  transform: scale(1.5);
  cursor: pointer;
}

.heroImgAllo {
  background: url(../../assets/Images/AllohaHills/g/m_5.jpg);
  height: 100vh;
  max-width: 100%;
  width: 100%;
  position: relative;
  width: "100%";
  max-height: 100vh;
  overflow: hidden;
  max-width: 100%;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  -webkit-animation: zoomhome 30s infinite alternate;
  animation: zoomhome 30s infinite alternate;
  background-position: center;
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
.imglogo {
  height: 40%;
  width: auto;
  margin-left: auto;
  margin-right: 0px;
  margin-top: -70px;
}
@media screen and (min-width: 768px) and (max-width: 1367px) {
  .HerosectionButtonStyle {
    width: 80%;
    max-width: 800px;
    top: 80%;
    bottom: 2%;
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}
@media (max-width: 768px) {
  .imglogo {
    height: 35%;
    margin-top: 50px;
    width: auto;
  }
}
@media (max-width: 480px) {
  .imglogo {
    height: 33%;
    width: auto;
    margin-top: 70px;
  }
  .heroImgAllo {
    background: url(../../assets/Images/AllohaHills/g/m_5.jpg);
    height: 100vh;
    max-width: 100%;
    width: 100%;
    position: relative;
    width: "100%";
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    -webkit-animation: zoomhome 30s infinite alternate;
    animation: zoomhome 30s infinite alternate;
    background-position: center;
  }
}
@media (max-width: 380px) {
  .imglogo {
    height: 28%;
    width: auto;
  }
  .heroImgAllo {
    background: url(../../assets/Images/AllohaHills/g/m_5.jpg);
    height: 100vh;
    max-width: 100%;
    width: 100%;
    position: relative;
    width: "100%";
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    -webkit-animation: zoomhome 30s infinite alternate;
    animation: zoomhome 30s infinite alternate;
    /* overflow-x: hidden; */
    background-position: center;
  }
}
@media (max-width: 200px) {
  .imglogo {
    height: 30%;
    width: auto;
  }
  .heroImgAllo {
    /* background-image: url(../../assets/Images/HomeCarouselImages/Inverness.png); */
    background: 
    /* linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), */ url(../../assets/Images/AllohaHills/g/m_5.jpg);
    height: 100vh;
    max-width: 100%;
    width: 100%;
    position: relative;
    width: "100%";
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    -webkit-animation: zoomhome 30s infinite alternate;
    animation: zoomhome 30s infinite alternate;
    /* overflow-x: hidden; */
    background-position: center;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1499px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
  /* .imglogo{
    height: 250px;
  width: 400px;
  } */
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
  /* .imglogo{
    height: 250px;
  width: 400px;
  } */
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 80%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 85%;
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1950px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
  /* .imglogo {
    height: 400px;
    width: 600px;
    margin-top: -200px;
  } */
}

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;

    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }

}

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 65%;
    right: 12%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    right: 15%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2601px) and (max-width: 3200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 48%;
    right: 21%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3201px) and (max-width: 3400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 38%;
    right: 28%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3401px) and (max-width: 4000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3501px) and (max-width: 6000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 6001px) and (max-width: 10000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 18%;
    right: 40%;
    background: transparent;
    height: auto;
    border: none;
  }
}
