.slick-dots li.slick-active button:before {
  height: 2px;
  width: 10px;
  background-color: black;
  color: black;
  border-radius: 0px;
}

.slick-dots li button:before {
  height: 2px;
  width: 10px;
  background-color: #f9f9f9;
  font-size: 0px;
}

.slick-dots {
  list-style-type: square;
}

.hoverImg {
  position: relative;
}

.caroImg {
  height: 550px;
  display: block;
  overflow: hidden;
  width: 97%;
  margin-left: 0;
  margin-right: auto;
}

.layer {
  background: transparent;
  height: 100%;
  width: 97%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 2s;
}

.layer:hover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 140%);
  transition: 7s;
}

.layer h5 {
  width: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 200;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.layer h3 {
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  left: 55%;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
}

.layer h3 .span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
}

.layer h6 {
  width: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.layer h5 {
  bottom: 19%;
  opacity: 0;
}

.layer h3 {
  bottom: 12%;
  opacity: 0;
}

.layer h6 {
  bottom: 1%;
  opacity: 0;
}
.layer:hover h5 {
  bottom: 19%;
  opacity: 1;
}

.layer:hover h3 {
  bottom: 12%;
  opacity: 1;
}

.layer:hover h6 {
  bottom: 1%;
  opacity: 1;
}

.layer-one-pic {
  background: transparent;
  height: 100%;
  width: 97%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 2s;
}

.layer-one-pic:hover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 140%);
  transition: 7s;
}

.layer-one-pic h5 {
  width: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 15px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-style: normal;
  font-family: "Playfair Display";
  font-weight: 200;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 8px;
  text-transform: uppercase;
  bottom: 19%;
  opacity: 0;
}

.layer-one-pic h3 {
  width: auto;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  left: 55%;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-style: normal;
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  bottom: 12%;
  opacity: 0;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.layer-one-pic h3 .span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
}

.layer-one-pic h6 {
  width: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  bottom: 1%;
  opacity: 0;
  text-align: center;
}

.layer-one-pic:hover h5 {
  bottom: 19%;
  opacity: 1;
}

.layer-one-pic:hover h3 {
  bottom: 12%;
  opacity: 1;
}

.layer-one-pic:hover h6 {
  bottom: 1%;
  opacity: 1;
}

@media (max-width: 480px) {
  .layer-one-pic {
    width: 97%;
  }

  .caroImg {
    height: 300px;
    width: 97.7%;
    margin-left: 0.3%;
    margin-right: auto;
  }

  .layer-one-pic {
    left: 0%;
    right: 0;
  }
}
@media (max-width: 320px) {
  .layer-one-pic {
    left: 5.5%;
  }
  .layer-one-pic {
    width: 90%;
  }
}

.carouselProjectImageHeight {
  height: 550px;
  width: 97%;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .carouselProjectImageHeight {
    height: 270px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 960px) {
  .carouselProjectImageHeight {
    height: 370px;
  }
}
