.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  /* transition: transform 1.2s; */
  transition: transform 1.8s ease-in-out;
  transform: translate3d(-3592px, 0px, 0px);
  /* transition: all 0s ease 0s; */
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
  color: #fff;
  width: 100vw;
  overflow-y: hidden;
}

/* .HomeCarousel {
  padding: relative;
} */

.SidebarButtonOne {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: all 2s ease-in-out;
}

.SidebarButtonWrapTwo {
  width: 100%;
  max-width: 70px;
  height: 100%;
  max-height: 600px;
  position: absolute;
  top: 0;
  border: none;
  right: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
}

.SidebarButtonWrapOne {
  width: 100%;
  max-width: 70px;
  height: 100%;
  max-height: 600px;
  position: absolute;
  top: 0;
  border: none;
  left: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
}

.SidebarButtonOne {
  display: block;
  opacity: 1;
}

.SidebarButtonTwo {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: all 2s ease-in-out;
}

.SidebarButtonTwo {
  opacity: 1;
}

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  background-color: black;
  color: #fff;
}

.CaaroButtonBoxStyle {
  width: 100%;
  max-width: 800px;
  position: absolute;
  top: 80%;
  right: 36%;
  background: transparent;
  height: auto;
  border: none;
}

@media screen and (min-width: 900px) and (max-width: 999px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 85%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1366px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1499px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 80%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 85%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1950px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 65%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 12%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 15%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2500px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: yellow; */
    /* 75% */
  }
}

@media screen and (min-width: 2501px) and (max-width: 2600px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: yellow; */
    /* 80% */
  }
}

@media screen and (min-width: 2601px) and (max-width: 2800px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 21%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: red; */
  }
}

@media screen and (min-width: 2801px) and (max-width: 3000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* background-color: green; */
    position: absolute;
    top: 48%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 22%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: aqua; */
    /* 67% */
  }
}

@media screen and (min-width: 3201px) and (max-width: 4000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 36%;
    /* background-color: maroon; */
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 30%;
    /* background: transparent; */
    height: auto;
    border: none;
    /* 50% */
  }
}

@media screen and (min-width: 4001px) and (max-width: 6000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 25%;
    /* background-color: maroon; */
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 37%;
    /* background: red; */
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 6001px) and (max-width: 8000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 18%;
    /* background-color: maroon; */
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 40%;
    /* background: yellow; */
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 5001px) and (max-width: 8000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 15%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 60%;
    /* background: transparent; */
    height: auto;
    /* background-color: red; */
    border: none;
  }
}
