@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../assets/Fonts/futuramediumbt.ttf") format("truetype");
  font-weight: normal;
}

.InvernessIntroText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.4);
  text-align: Justify;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.HpaIntroTExt {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.OurPartnerText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: center;

  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.ourArchitecText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #808186;
  text-align: center;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.AmentiseText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #bababa;
  text-align: Justify;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.visionmissiontext {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #979797;
  text-align: Justify;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.ourArchitecText,
.ourArchitecText span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.InvernessIntroText,
.InvernessIntroText span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.OurPartnerText,
.OurPartnerText span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.ourArchitecText span {
  display: block;
}

.OurPartnerText span {
  display: inline-block;
}

.InvernessIntroText span {
  display: inline-block;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}

.LinkStyleText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #5392bf;
  text-align: Justify;
  animation-duration: 1s;
  animation-fill-mode: both;
  text-align: center;
}

.Amentise-Div-Image {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/Images/Inverness/Amentise.png);
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 450px;
  max-height: 100vh;
  overflow: hidden;
  max-width: 100%;
  /* background-color: red; */
  max-width: auto;
  overflow: hidden;
  -webkit-transition: all 4s ease-in-out;
  -moz-transition: all 4s ease-in-out;
  -o-transition: all 4s ease-in-out;
  -ms-transition: all 4s ease-in-out;
  transition: all 4s ease-in-out;
  animation: imageSyle 5s ease-in-out;
}

.AllohaIntroImgOne {
  background-image: url("../../assets/Images/AllohaHills/g/m (1).jpg");
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.AllohaIntroImgTwo {
  background-image: url("../../assets/Images/AllohaHills/g/inverness_360newImgcropped.jpg");
  width: 100%;
  height: 100%;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.AllohaIntroImgThree {
  background-image: url("../../assets/Images/AllohaHills/g/m (2).jpg");

  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.MedhanshIntroImgOne {
  background-image: url(../../assets/Images/Medhaansh/Medhaansh.png);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.MedhanshIntroImgOne {
  background-image: url(../../assets/Images/Medhaansh/Medhaansh.png);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.MedhanshIntroImgTwo {
  background-image: url(../../assets/Images/Medhaansh/Rect6.jpg);
  width: 98%;
  height: 100%;
  min-height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.MedhanshIntroImgTree {
  background-image: url(../../assets/Images/Medhaansh/Rect5.png);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.ImgLogoMed {
  height: auto;
  width: 30%;
  margin-left: auto;
  margin-right: 0px;
  margin-top: 2px;
  margin-bottom: 20px;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .visionmissiontext {
    font-size: 16px;
    line-height: 25px;
  }
  .OurPartnerText {
    font-size: 14px;
    line-height: 25px;
  }
  .ImgLogoMed {
    height: auto;
    width: 300px;
    min-width: 50% !important;
    /* background-color: red; */
  }
}
@media (max-width: 480px) {
  .ImgLogoMed {
    height: auto;
    width: 300px;
    margin-top: 50px;
  }
  /* .audioSetting{
    margin-top: 2px;
    margin-left:110px;
    margin-right: auto
  } */
}

/* @media screen and (max-width: 600px){
  .gal-img{
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 480px){
  .gal-img{
    margin-bottom: 20px;
  }
} */

.cssanimation,
.cssanimation span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
.heroImgMed {
  /* background-image: url(../../assets/Images/HomeCarouselImages/Inverness.png); */
  background:
   /* linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), */ url(../../assets/Images/Medhaansh/Rect8.png);
  height: 100vh;
  max-width: 100%;
  width: 100%;
  position: relative;
  width: "100%";
  z-index: 0;

  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  animation: zoomhome 30s infinite alternate;
  /* overflow-x: hidden; */
  background-position: center;

  /* height: "100%",  
  minHeight: "800px",
  maxHeight: "100vh",
  position: "relative",
  background: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url(${AllohaImg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover" */
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

.SpecificationTextStyle {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  text-transform: uppercase;

  color: #333333;
}

.HerosectionButtonStyle {
  width: 100%;
  max-width: 800px;
  position: absolute;
  top: 10%;
  /* bottom: 0%; */

  /* transform: translateY(-50%); */
  right: 10%;
  background: transparent;
  height: auto;
  border: none;
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
  .InvernessIntroText {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.4);
    text-align: Justify;
    margin-bottom: 20px;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 370px;
    max-height: 370px;
    margin-top: 50px;
    width: 100%;
  }
  .AllohaIntroImgTwo{
    height: 300px;
    max-height: 300px;
    margin-top: 60px;
  }
}


@media screen and (min-width: 1001px) and (max-width: 1100px) {
  .InvernessIntroText {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.4);
    text-align: Justify;
    margin-bottom: 20px;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 370px;
    max-height: 370px;
    margin-top: 50px;
    width: 100%;
  }
  .AllohaIntroImgTwo{
    height: 300px;
    max-height: 300px;
    margin-top: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1367px) {
  .HerosectionButtonStyle {
    width: 80%;
    max-width: 800px;
    /* position: absolute; */
    top: 80%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }

  .ImgLogoMed {
    height: auto;
    width: 40%;
  }
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 450px;
    max-height: 600px;
    width: 100%;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1499px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 80%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 85%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1950px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 65%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 12%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 15%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2601px) and (max-width: 3200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 48%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 21%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3201px) and (max-width: 3400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 38%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 28%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3401px) and (max-width: 4000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3501px) and (max-width: 6000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 6001px) and (max-width: 10000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 18%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 40%;
    background: transparent;
    height: auto;
    border: none;
  }
}
@media (min-height: 450px) and (max-width: 600px) {
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 450px;
    width: 100%;
  }
  .AllohaIntroImgTwo {
    height: 100%;
    min-height: 450px;
    width: 100%;
  }
}

@media (min-height: 0px) and (max-width: 449px) {
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 300px;
    width: 100%;
  }
  .AllohaIntroImgTwo {
    height: 100%;
    min-height: 400px;
    width: 100%;
  }
}
