.img-filter {
  filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  filter: grayscale(0%);
}

.img-zoom:hover {
  background-size: 100% 80%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}
.InOneImg {
  width: 98%;
  height: auto;
  min-height: 325px;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/1_Club_house_Secor_A_new.jpg);
}

.InTwoImg {
  width: 98%;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/3_Garden_Sector_A_new.jpg);
}

.InThreeImg {
  margin-top: 5px;
  width: 98%;
  max-width: 98%;
  overflow: hidden;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/6_Sample_House_new.jpg);
}

.InFourImg {
  margin-top: 5px;
  width: 98%;
  max-width: 98%;
  overflow: hidden;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/10_Sample_House.jpg);
}

.InFiveImg {
  margin-bottom: 5px;
  width: 98%;
  min-height: 325px;
  height: 90%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/12_Sample_House_new.jpg);
}

.InSixImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/18_Garden_Sectoe_A_new.jpg);
}

.InSevenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/19_Club_house_Secor_A_new.jpg);
}

.InEightImg {
  width: 98%;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/21_Club_house_Secor_A_new.jpg);
}

.InNineImg {
  width: 98%;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/22_Sample_house.jpg);
}
.InTenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/14_Wood_house_SectorB.jpg);
}
.InElevenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/WhatsApp11.jpeg);
}
.InTweleveImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/consupdate12.jpg);
}

.InThirteenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/view1.jpeg);
}

.InForteenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/view2.jpeg);
}

.InFifteenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/view3.jpeg);
}

.InSixteenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/view4.jpeg);
}

.InseventeenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/view5.jpeg);
}

.InEightteenImg {
  width: 98%;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Inverness/Inverness/view6.jpeg);
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .InOneImg {
    height: 280px;
  }
  .InTwoImg {
    height: 280px;
  }
  .InThreeImg {
    height: 280px;
  }
  .InFourImg {
    height: 280px;
  }
  .InFiveImg {
    height: 280px;
  }
  .InSixImg {
    height: 280px;
  }
  .InSevenImg {
    height: 280px;
  }
  .InEightImg {
    height: 280px;
  }
  .InNineImg {
    height: 280px;
  }
  .InTenImg {
    height: 280px;
  }
  .InElevenImg {
    height: 280px;
  }
  .InTweleveImg {
    height: 280px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1100px) {
  .InOneImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTwoImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InThreeImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InFourImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InFiveImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InSixImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InSevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InEightImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InNineImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InElevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTweleveImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InThirteenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InForteenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InFifteenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InSixteenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InseventeenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InEightteenImg {
    min-height: 250px;
    max-height: 250px;
  }
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .InOneImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InTwoImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InThreeImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InFourImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InFiveImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InSixImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InSevenImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InEightImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InNineImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InElevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTweleveImg {
    min-height: 250px;
    max-height: 250px;
  }
}
/* @media (max-width: 480px){
    .InOneImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InTwoImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InThreeImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InFourImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InFiveImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InSixImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InSevenImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InEightImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InNineImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InTenImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InElevenImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InTweleveImg {
      max-height: 350px;
      min-height: 350px;
    }
  }
   */
