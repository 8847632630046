.img-filter {
  filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  filter: grayscale(0%);
}

.img-zoom:hover {
  background-size: 110% 110%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}
.Rect1 {
  width: 98%;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect1.jpg);
}

.Rect2 {
  width: 98%;
  height: 98%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect6.jpg);
}

.Rect3 {
  margin-top: 5px;
  width: 98%;
  max-width: 98%;
  overflow: hidden;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect3.png);
}

.Rect4 {
  margin-top: 5px;
  /* margin-right: 5px; */
  width: 98%;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect4.jpg);
}

.Rect5 {
  width: 98%;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect5.jpg);
}

.Rect6 {
  width: 98%;

  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect6.jpg);
}

.Rect7 {
  width: 98%;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect7.jpg);
}

.Rect8 {
  width: 98%;

  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect8.jpg);
}

.Rect9 {
  width: 98%;

  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect9.png);
}

/* .OneImg {
  width: 98%;
  height: 100%;
  min-height: 500px;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect1.png);
}

.TwoImg {
  width: 98%;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect2.png);
} */
/* 
.Rect1:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect2:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect3:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect4:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect5:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect6:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

@keyframes zoomin {
  from {
    background-size: auto;
  }
  to {
    background-size: 110%;
  }
} */
@media screen and (min-width: 0px) and (max-width: 600px) {
  .Rect1 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect2 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect3 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect4 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect5 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect6 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect7 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect8 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect9 {
    min-height: 200px;
    max-height: 200px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 800px) {
  .Rect1 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect2 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect3 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect4 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect5 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect6 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect7 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect8 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect9 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .Rect1 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect2 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect3 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect4 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect5 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect6 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect7 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect8 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect9 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
}
