@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../assets/Fonts/futura\ medium\ condensed\ bt.ttf")
      format("truetype");
  font-weight: normal;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  width: 120%;
  border: 0.5px solid #868686;
}

.MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.css-1xc9rp1-MuiInputBase-root-MuiInput-root {
  border: 1px solid #f1e9e9;
}

textarea {
  font: inherit;
  resize: "none";
  letter-spacing: inherit;
  color: currentColor;
  width: 98%;
}

.InputBoxstyle {
  width: "100%";
  max-width: "235px";
  margin-left: "auto";
  margin-right: "auto";
  height: "50px";
  border: "1px solid #F4F4F4";
  border-radius: " 0px";
  padding-left: "10px";
  margin-top: "15px";
  font-style: "normal";
  font-weight: 400;
  font-size: "16px";
  line-height: "25px";
  text-align: "left";
  letter-spacing: "0.05em";
  color: "#868686";
}

.contact-us-bg-image {
  width: 100%;
  height: 380px;
  background: url(../../assets/Images/ContactUsImages/Rectangle-1.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 1500px) {
  .LastInputBoxStyle {
    width: 98%;
  }
}
