.InputBoxStyle1 {
  width: 92%;
  max-width: 250px;
  margin-top: 18px;
  height: 55px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  align-items: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.InputBoxStyle2 {
  width: 92%;
  max-width: 250px;
  margin-top: 18px;
  height: 55px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  align-items: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ContactInputMsgBox {
  width: 95%;
  max-width: 530px;
  margin-top: 18px;
  height: 55px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.LastInputBoxStyle {
  width: 93%;
  max-width: 550px;
  margin-top: 18px;
  height: 160px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media only screen and (min-width: 0px) and (max-width: 400px) {
  .ContactInputMsgBox {
    width: 99%;
    max-width: 340px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle1 {
    width: 100%;
    max-width: 340px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 100%;
    max-width: 340px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 94%;
    max-width: 340px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
  .ContactInputMsgBox {
    width: 99%;
    max-width: 350px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle1 {
    width: 100%;
    max-width: 350px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 100%;
    max-width: 350px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 94%;
    max-width: 350px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .ContactInputMsgBox {
    width: 99%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle1 {
    width: 100%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 100%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 94%;
    max-width: 400px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .InputBoxStyle1 {
    width: 85%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 85%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  /* .LastInputBoxStyle {
    width: 93%;
    max-width: 400px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  } */
}

@media screen and (min-width: 1201px) and (max-width: 2000px) {
  .InputBoxStyle1 {
    width: 90%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 90%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 95%;
  }
}
