.CLientSayText {
  font-family: "Playfair Display";
}

.slick-dots li.slick-active button {
  /* font-size: -5px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: black;
  color: black;
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}
.slick-dots li.slick-active button::before {
  /* font-size: 15px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: black;
  /* color: black; */
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}
.slick-dots li.slick-active button::before {
  /* font-size: 10px; */
  height: 0px;
  width: 22px;
  /* line-height: 10px; */
  background-color: black;
  color: black;
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}
/* .slick-dots li button:before */
.slick-dots li button {
  height: 0px;
  width: 22px;
  /* line-height: 10px; */
  /* font-size: 10px; */
  /* color: lightblue; */
  background-color: #f9f9f9;
  /* font-size: 0px; */
  /* border: 0.2px black; */
  /* margin-bottom: 5px; */
}
.slick-dots li button::before {
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  /* font-size: 15px; */
  /* color: lightblue; */
  background-color: #f9f9f9;
  font-size: 0px;
  /* border: 0.2px black; */
  /* margin-bottom: 5px; */
}
/* ul .slick-dots {
  list-style-type: square;
} */
.slick-dots.slick-thumb {
  margin-top: 50px;
}
.slick-dots {
  bottom: -114px;
}
.HeroSidebarButtonOne {
  height: 100%;
}
.HeroSidebarButtonTwo {
  height: 100%;
}
.slick-arrow.slick-next::before,
.slick-arrow.slick-prev::before {
  display: none;
  /* font-size: 0px; */
}

.slick-dotsCaro {
  /* font-size: -5px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: transparent;
  color: transparent;
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}

.slick-dotsCaro li.slick-active button {
  /* font-size: -5px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: black;
  color: black;
  /* border-radius: 0px; */
  border: none;
}
.slick-dotsCaro li.slick-active button::before {
  /* font-size: 15px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: black;
  /* color: black; */
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}

.slick-dotsCaro li button {
  height: 0px;
  width: 22px;
  /* line-height: 10px; */
  /* font-size: 10px; */
  /* color: lightblue; */
  background-color: #f9f9f9;
  /* font-size: 0px; */
  /* border: 0.2px black; */
  /* margin-bottom: 5px; */
}
.slick-dotsCaro li button::before {
  height: 0px;
  width: 22px;
  background-color: #f9f9f9;
  font-size: 0px;
}

.slick-dotsCaro .slick-thumb-caro {
  margin-top: 50px;
}

.slick-dotsCaro {
  bottom: -114px;
}

.custom-indicatornewcaro {
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  /* filter: grayscale(100%); */
}

.custom-indicatornewcaro li {
  height: 2px;
  width: 12px;
  max-width: 20px;
  margin-left: auto;
  margin-right: auto;
  /* border-top: 3px solid #FFFFFF; */
  padding-top: 1;
  padding-left: auto;
  padding-right: auto;
  border: none;
  background-color: rgb(222, 222, 222);
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  /* background-color: transparent; */
}
.custom-indicatornewcaro li.slick-active {
  /* filter: grayscale(0%); */
  border: none;
  background-color: gray;
  height: 2px;
  width: 12px;
  max-width: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* new */

.custom-indicatornewcaro1 {
  position: absolute;
  top: 105%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  /* filter: grayscale(100%); */
}

.custom-indicatornewcaro1 li {
  height: 2px;
  width: 12px;
  max-width: 20px;
  margin-left: auto;
  margin-right: auto;
  /* border-top: 3px solid #FFFFFF; */
  padding-top: 1;
  padding-left: auto;
  padding-right: auto;
  border: none;
  background-color: rgb(222, 222, 222);
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  /* background-color: transparent; */
}
.custom-indicatornewcaro1 li.slick-active {
  /* filter: grayscale(0%); */
  border: none;
  background-color: gray;
  height: 2px;
  width: 12px;
  max-width: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1024px) {
  .custom-indicatornewcaro {
    top: 105%;
  }
}
@media (max-width: 900px) {
  .HeroSidebarButtonOne {
    height: 100%;
  }
  .HeroSidebarButtonTwo {
    height: 100%;
  }
}
@media (max-width: 768px) {
  .custom-indicatornewcaro {
    top: 109%;
  }
  .HeroSidebarButtonOne {
    font-size: 10px;
  }
  .HeroSidebarButtonTwo {
    font-size: 10px;
  }
}
@media (max-width: 480px) {
  .custom-indicatornewcaro {
    top: 112%;
  }
  .HeroSidebarButtonOne {
    height: 100%;
  }
  .HeroSidebarButtonTwo {
    height: 100%;
  }
}
