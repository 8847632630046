@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Emblema+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Allura&display=swap%27);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arizonia&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kalam&family=Satisfy&display=swap);

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuramediumbt.ee64fb9d.ttf) format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: Futura Medium BT, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.img-zoom-ani {
  -webkit-animation: zoomhome 30s infinite alternate;
          animation: zoomhome 30s infinite alternate;
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

.img-filter {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  -webkit-filter: grayscale(0%);

          filter: grayscale(0%);
}

.img-zoom:hover {
  background-size: 110% 110%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}

@font-face {
  font-family: "Snell Roundhand";
  src: url(/static/media/snell.fdf5df96.ttf);
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuramediumbt.ee64fb9d.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Snell Roundhand";
  src: url(/static/media/snell.fdf5df96.ttf);
}
@font-face {
  font-family: "Futura Italic BT";
  src: local("Futura Italic BT"),
    url("/static/media/Futura Medium Italic font.95d5a438.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Futura Italic md";
  src: local("Futura Italic md"),
    url("/static/media/Futura Book Italic font.2fb9f47c.ttf") format("truetype");
  font-weight: normal;
}
:root {
  margin: 0;
  font-family: "Futura Medium BT";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  font-family: "Playfair Display";
  scroll-behavior: smooth;
  width: 100%;
  padding: 0px;
  max-width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
}
::-webkit-scrollbar {
  display: none;
}
.loaderImg{
  width: 450px;
  height: auto;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input:focus {
  outline: 2px solid #000;
}
textarea:focus {
  outline: 2px solid #000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-input-placeholder {
  font-family: "Playfair Display";
}

::placeholder {
  font-family: "Playfair Display";
}

input[type="text"] {
  font-family: Playfair Display;
}
textarea {
  font-family: Playfair Display;
}

input:focus {
  border: 1px solid black;
}

#ReactSimpleImageViewer {
  z-index: 100 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary.css-19nnow0-MuiInputBase-root-MuiInput-root:focus {
  border: 1px solid #000000;
}

.footerbgcolor {
  background-color: #f9f9f9;
  width: "100%";
  height: "100%";
  padding-top: "10px";
  padding-bottom: "10px";
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.hover-shadow {
  transition: box-shadow 0.3s ease-in-out;
}

.hover-shadow:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.img-filter {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  -webkit-filter: grayscale(0%);

          filter: grayscale(0%);
}

.front-img-zoom {
  -webkit-animation: scale-img 5s ease-out;
          animation: scale-img 5s ease-out;
}
@-webkit-keyframes scale-img {
  from {
    scale: 1.2;
  }
  to {
    scale: 1;
  }
}
@keyframes scale-img {
  from {
    scale: 1.2;
  }
  to {
    scale: 1;
  }
}

.styles-module_close__2I1sI {
  top: 15px !important;
  right: 20px !important;
  opacity: 0.8 !important;
}

.styles-module_close__2I1sI:hover {
  opacity: 1 !important;
}

.slick-track {
  display: flex;
  place-items: center;
  align-items: center;
}

@media (max-width: 900px) {
  .front-img-RL {
    background: url(/static/media/mainLoader.e0bdc1f6.jpg);
    background-size: inherit;
    background-position: left;
    -webkit-animation: newimagestyle 10s infinite alternate;
            animation: newimagestyle 10s infinite alternate;
  }

  .HomeLoaderMobile {
    background: url(/static/media/mainLoader.e0bdc1f6.jpg);
    background-size: cover;
    background-position: center;
    animation: newimagestyle 15s infinite alternate;
    -webkit-animation: newimagestyle 15s infinite alternate;
  }
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12.MuiGrid-grid-xl-12.css-1vkfnbl-MuiGrid-root {
  margin-top: 50px;
}
@-webkit-keyframes newimagestyle {
  from {
    background-position: right;
  }
  to {
    background-position: center;
  }
}
@keyframes newimagestyle {
  from {
    background-position: right;
  }
  to {
    background-position: center;
  }
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 1px 1px 1px 1px !important;
}
.css-1vtqu8q-MuiTypography-root {
  padding-top: 0px !important;
}

.dropdown_item {
  background: rgb(255 255 255 / 75%) !important;
  padding-top: 0px !important;
  text-align: -webkit-left !important;
  padding-left: 30px !important;
}
.dropdown_item_two {
  margin-left: 10px;
  left: 193px !important;
}
.dropdown_menu_home {
  -webkit-padding-start: 0px !important;
          padding-inline-start: 0px !important;
}
.dropdown_item_three {
  left: 202px !important;
}

.dropdown_item {
  text-align: center !important;
  padding-left: 0px !important;
}

.Dropdowntextheader {
  text-align: center !important;
}
.dropdown_menu_home {
  margin-left: 0px !;
}

.whatsappicon {
  width: 40px;
  position: fixed;
  top: 92%;
  left: 02%;
  z-index: 10;
}
.dropdown_menu_home {
  margin-left: 0px !important;
}
audio {
  width: 100px;
  height: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
}

audio::-webkit-media-controls-mute-button {
  display: none !important;
  background: transparent !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
  background: transparent !important;
}

audio::-webkit-media-controls-volume-control-container.closed {
  display: none !important;
  background: transparent !important;
}
audio::-webkit-media-controls-volume-control-container {
  display: none !important;
}

@media (max-width:450px) {
  .loaderImg{
    width: 350px;
    height: auto;
  }
}
@media (max-width:350px) {
  .loaderImg{
    width: 320px;
    height: auto;
  }
}
@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuramediumbt.ee64fb9d.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuralightbt.98d2f973.ttf) format("truetype");
  font-weight: normal;
}

.card-container {
  cursor: pointer;
  height: 400px;
  width: 313px;
  -webkit-perspective: 600;
          perspective: 600;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
/*card to fit the size of the card container by giving the width and height of 100% and position of absolute which is relative to the card container*/
.card {
  height: 100%;
  width: 100%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: absolute;
  transition: all 1s ease-in-out;
}
/*card rotate from y axis on hover*/
.card:hover {
  -webkit-transform: rotatey(180deg);
          transform: rotatey(180deg);
}
/*Front side of the card with the property of backface visibility to make the other side of card hidden*/
.side {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 6px;
  background-color: white;
}
/*back side of the card*/
.back {
  -webkit-transform: rotatey(180deg);
          transform: rotatey(180deg);
  background-color: white;
}

/* cards css end */
.ourProjectText {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #908186;
  text-align: center;
  margin-bottom: 20px;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.AboutUs-Background {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/Asset.f1b54ce0.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  /* position: relative; */
  background-size: cover;
  background-position: center;
  min-height: 450px;
  /* background-color: red; */
  max-width: auto;
  overflow: hidden;
  margin-top: "10px";
  /* position: relative; */
}

.AboutHomeText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #808186;
  text-align: Justify;
  /* margin-bottom: 20px; */
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ModalTextHome {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #808186;
  text-align: Justify;
  /* margin-bottom: 20px; */
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.OurHistoryTextStyle {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
  color: #808186;
  text-align: Justify;
  /* margin-bottom: 20px; */
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.CLientSayText {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #808186;
  text-align: center;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.OurPartnerText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: center;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.AmentiseText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #bababa;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.visionmissiontext {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #979797;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.HeroText,
.HeroText span {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.OurPartnerText,
.OurPartnerText span {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.OurPartnerText span {
  display: inline-block;
}

.HeroText span {
  display: inline-block;
}

.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
  animation-name: fadeInBottom;
}

.LinkStyleText {
  font-family: Futura Medium BT;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #5392bf;
  text-align: Justify;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  text-align: center;
  align-items: flex-end;
}
.indicators > button {
  width: 12px;
  border: 0px;
  border-radius: 50%;
  height: 12px !important;
}

@-webkit-keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .visionmissiontext {
    font-size: 16px;
    line-height: 25px;
  }
  .OurPartnerText {
    font-size: 14px;
    line-height: 25px;
  }
  .AboutHomeText {
    line-height: 20px;
  }
  .ModalTextHome {
    font-size: 14px;
    line-height: 20px;
  }
}

.CaroDivOne {
  max-width: 100vw;
  width: 100vw;
  height: 105vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(/static/media/18_Garden_Sectoe_A_new.6e713783.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-y: hidden;
  z-index: 0;
  -webkit-animation: zoomhome 25s infinite alternate;
  animation: zoomhome 25s infinite alternate;
}

.HeroText {
  font-family: Futura Medium BT;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #f3f3f3;
  text-align: right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  word-wrap: break-word;
  /* display: "flex" */
  white-space: pre-wrap;
  margin-top: 30px;
  -webkit-animation: fadeInBottom 1s ease-in-out;
  animation: fadeInBottom 1s ease-in-out;
}

.CaroDivTwo {
  max-width: 100vw;
  width: 100vw;
  height: 105vh;
  background: url(/static/media/cam_03_FFF-min.7f87cc52.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-animation: zoomhome 25s infinite alternate;
  animation: zoomhome 25s infinite alternate;
}
.CaroDivThreePhone {
  max-width: 100vw;
  width: 100vw;
  height: 100%;
  min-height: 50vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(/static/media/Inverness.900f05d6.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-y: hidden;
  z-index: 0;
  /* animation: zoomhome 25s infinite alternate; */
}
.CaroDivTwoPhone {
  max-width: 100vw;
  width: 100vw;
  height: 100%;
  min-height: 50vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(/static/media/cam_03_FFF-min.7f87cc52.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-y: hidden;
  z-index: 0;
  /* animation: zoomhome 25s infinite alternate; */
}

.CaroDivOnePhone {
  max-width: 100vw;
  width: 100vw;
  height: 100%;
  min-height: 50vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(/static/media/18_Garden_Sectoe_A_new.6e713783.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-y: hidden;
  z-index: 0;
  /* animation: zoomhome 25s infinite alternate; */
}
.CaroDivThree {
  max-width: 100vw;
  width: 100vw;
  z-index: 0;
  height: 105vh;
  background: 
  /* linear-gradient(rgba(58, 57, 57, 0.0), rgba(29, 28, 28, 0.0)), */ url(/static/media/Inverness.900f05d6.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  -webkit-animation: zoomhome 25s infinite alternate;
  animation: zoomhome 25s infinite alternate;
}

@-webkit-keyframes imageSyle {
  0% {
    opacity: 1;
    background-size: 100%;
  }
  100% {
    opacity: 1;
    background-size: 110%;
  }
}

@keyframes imageSyle {
  0% {
    opacity: 1;
    background-size: 100%;
  }
  100% {
    opacity: 1;
    background-size: 110%;
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.CLientSayText {
  /* font-family: Futura Medium BT; */
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: "22px";
  text-align: "center";
  color: "#808186";
  margin-top: 20px;
  margin-bottom: 20px;
  /* white-space: "pre-wrap"; */
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* .AboutSectionOfHome {
  height: 100%;
  min-height: 600px;
  max-height: 900px;
  width: 100%;
  background-color: red;
} */

.PreviouProjectDivOne {
  background: url(/static/media/1_Club_house_Secor_A_new.01279ef7.jpg);
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 375px;
  /* background: url(../../assets/Images/HomeImages/6_Sample_House_new.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivTwo {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 375px;
  background: url(/static/media/6_Sample_House_new.dcf09406.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivThree {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(/static/media/3_Garden_Sector_A_new.6e2cc8a9.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivFour {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(/static/media/12_Sample_House_new.9ec33573.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivFive {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(/static/media/21_Club_house_Secor_A_new.6082a0d6.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivSix {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(/static/media/19_Club_house_Secor_A_new.358878ae.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivSeven {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(/static/media/caraimg.1ed4c1ab.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivEight {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(/static/media/caraeight.275e4a22.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}
.PreviouProjectDivNine {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(/static/media/14_Wood_house_SectorB.6ab6abdf.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}
/* .PreviouProjectDivSeven {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 500px;
  max-height: 600px;
  background: url(../../assets/Images/HomeImages/21_Club_house_Secor_A.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  animation: zoomhome 20s ease-in-out;
}
*/
.PreviouProjectDivEight {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(/static/media/22_Sample_house.1effe61e.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  /* animation: zoomhome 20s ease-in-out; */
}

/* .PreviouProjectDivNine {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 675px;
  max-height: 675px;
  background: url(../../assets/Images/HomeImages/14_Wood_house_SectorB.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  animation: zoomhome 20s ease-in-out;
} */
.HeroSidebarButtonWrapOne {
  width: 100%;
  max-width: 50px;
  height: 100%;
  /* max-height: 500px; */
  position: absolute;
  top: 0;
  border: none;
  left: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
}

.HeroSidebarButtonWrapTwo {
  width: 100%;
  max-width: 50px;
  height: 100%;
  /* max-height: 500px; */
  position: absolute;
  top: 0;
  border: none;
  right: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
}

.PrevSidebarButtonWrapOne {
  width: 100%;
  max-width: 50px;
  height: 100%;
  max-height: 88vh;
  position: absolute;
  top: 0;
  border: none;
  left: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
}

.PrevSidebarButtonWrapTwo {
  width: 100%;
  max-width: 50px;
  height: 100%;
  max-height: 88vh;
  position: absolute;
  top: 0;
  border: none;
  right: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
}

.HeroSidebarButtonOne {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  background: linear-gradient(
    90deg,
    rgba(19, 18, 18, 0.8) 0%,
    rgba(77, 77, 77, 0) 100%
  );
  opacity: 0.5;
  background: transparent;

  transition: all 1.5s ease-in-out;
}

.PrevSidebarButtonOne {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  background: linear-gradient(
    90deg,
    rgba(19, 18, 18, 0.8) 0%,
    rgba(77, 77, 77, 0) 100%
  );
  opacity: 0.5;
  background: transparent;

  transition: all 1.5s ease-in-out;
}

.HeroSidebarButtonWrapOne:hover .HeroSidebarButtonOne {
  display: block;
  opacity: 1;
}

.HeroSidebarButtonTwo {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  opacity: 0.5;

  /* background: linear-gradient(
    -90deg,
    rgba(19, 18, 18, 0.8) 0%,
    rgba(249, 249, 249, 0) 100%
  ); */
  background: transparent;
  transition: all 1.5s ease-in-out;
}

.PrevSidebarButtonTwo {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  opacity: 0.5;

  /* background: linear-gradient(
    -90deg,
    rgba(19, 18, 18, 0.8) 0%,
    rgba(249, 249, 249, 0) 100%
  ); */
  background: transparent;
  transition: all 1.5s ease-in-out;
}

.HeroSidebarButtonWrapTwo:hover .HeroSidebarButtonTwo {
  opacity: gray;
}

.PrevSidebarButtonWrapOne:hover .HeroSidebarButtonOne {
  opacity: gray;
}
/* new style */

.main-slider {
  /* background-color: red; */
  position: relative;
}
.main-slider .content {
  position: relative;
}
.main-slider .slide {
  position: relative;
  padding: 395px 0px 230px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.main-slider .slide:before {
  position: absolute;
  content: "";
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 9;
}
.main-slider h1 {
  position: relative;
  color: #ffffff;
  font-size: 35px;
  font-weight: bold;
  /*font-weight: 600;*/
  line-height: 32px;
  letter-spacing: 5px;
  margin-bottom: 30px;
  text-transform: uppercase;
  opacity: 0;
  transition: all 1000ms ease;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
}
.main-slider .active h1 {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition-delay: 1300ms;
}
.main-slider h5 {
  position: relative;
  color: #777;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-block;
  text-transform: uppercase;
  opacity: 0;
  transition: all 1000ms ease;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
}
.main-slider .active h5 {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition-delay: 1000ms;
}
.main-slider .link-box {
  opacity: 0;
  transition: all 1000ms ease;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
}
.main-slider .link-box .theme-btn {
  color: #fff;
  border-color: #fff;
}
.main-slider .active .link-box {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition-delay: 2500ms;
}
.main-slider .content .title {
  position: relative;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;
  margin-bottom: 25px;
  opacity: 0;
  transition: all 1000ms ease;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
}
.main-slider .content .title:after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0px;
  width: 70px;
  height: 1px;
  margin-left: -35px;
  background-color: #ffffff;
}
.main-slider .active .title {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition-delay: 600ms;
}
.main-slider .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 51px;
}
.main-slider .owl-dots .owl-dot {
  display: none;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0px 4px;
  /*display: inline-block;*/
  border: 4px solid rgba(255, 255, 255, 0.2);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-slider .owl-dots .owl-dot:hover,
.main-slider .owl-dots .owl-dot.active {
  border-color: rgba(255, 255, 255, 0.6);
}
.main-slider .owl-nav {
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 1;
  width: 100%;
  margin-top: -20px;
  opacity: 0;
  transition: 0.5s ease;
}
.main-slider:hover .owl-nav {
  opacity: 1;
}
.main-slider .owl-nav .owl-prev {
  position: absolute;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  opacity: 0.3;
  left: 25px;
  /*width: 78px;
    height: 78px;*/
  /*color: rgba(255, 255, 255, 0.5);
  /*line-height: 62px;*/
  text-align: center;
  font-size: 21px;
  /*border: 8px solid rgba(255, 255, 255, 0.30);*/
  display: inline-block;
  /*background-color: rgba(255, 255, 255, 0.10);*/
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-slider .owl-nav .owl-next {
  position: absolute;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  opacity: 0.3;
  right: 25px;
  /* width: 78px;
  height: 78px;*/
  /*color: rgba(255, 255, 255, 0.50);
  line-height: 62px;*/
  text-align: center;
  font-size: 21px;
  /* border: 8px solid rgba(255, 255, 255, 0.30);*/
  display: inline-block;
  /*background-color: rgba(255, 255, 255, 0.10);*/
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-slider .owl-nav .owl-prev:hover,
.main-slider .owl-nav .owl-next:hover {
  color: #fff;
  border-color: #fff;
  opacity: 0.8;
}
.main-slider .left-side {
  position: absolute;
  left: 120px;
  top: 0;
  width: 110px;
  height: 100%;
  padding: 60px 0;
  background: #f58634;
  z-index: 9;
}
.main-slider .left-side h4 {
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s ease;
  -webkit-transform: rotate(-90deg) translateX(-100%) translateY(-54%);
          transform: rotate(-90deg) translateX(-100%) translateY(-54%);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  left: 50%;
  width: -webkit-max-content;
  width: max-content;
  letter-spacing: 30px;
  opacity: 0;
  transition: 0.7s ease;
}
.main-slider .left-side h4.now-in-view {
  opacity: 1;
  letter-spacing: 7px;
}
.main-slider .left-side h4:before {
  position: absolute;
  content: "";
  background: #fff;
  height: 1px;
  width: 60px;
  left: -80px;
  top: 12px;
  -webkit-transform: translate(-10px);
          transform: translate(-10px);
}
.main-slider .left-side .social-icon {
  text-align: center;
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
}
.main-slider .left-side .social-icon li {
  margin-bottom: 20px;
}
.main-slider .left-side .social-icon li:last-child {
  margin-bottom: 0px;
}
.main-slider .left-side .social-icon li a {
  color: #fff;
  font-size: 22px;
  transition: 0.5s ease;
}
.main-slider .left-side .social-icon li a:hover {
  color: #222;
  -webkit-transform: rotate(-360deg) scale(2);
          transform: rotate(-360deg) scale(2);
  display: inline-block;
}
/* Main slider style two */
.main-slider.style-two {
  padding-left: 230px;
}
.main-slider.style-two:before {
  position: absolute;
  content: "";
  background: #222;
  left: 0;
  top: 0;
  width: 120px;
  height: 90%;
}
.main-slider.style-two .auto-container {
  max-width: 1430px;
}
.main-slider.style-two .slide {
  padding-top: 250px;
  padding-bottom: 240px;
}
.main-slider.style-two .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 51px;
  max-width: 1400px;
  width: 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 0 10px;
}
.main-slider.style-two .owl-nav .owl-prev {
  left: auto;
  right: 120px;
  top: -44px;
}
.main-slider.style-two .owl-nav .owl-next {
  top: 44px;
}

/* Card flip css */

.flip-card {
  background-color: transparent;
  width: 400px;
  height: 400px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  margin-left: "auto";
  margin-right: auto;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 1.5s;
  transition: transform 1.5s;
  transition: transform 1.5s, -webkit-transform 1.5s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  background-color: #fff;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  /* background-color: rgba(233, 233, 233, 0.46); */
  background-color: #fff;
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

@media (min-width: 481px) and (max-width: 900px) {
  .PreviouProjectDivOne {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivTwo {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivThree {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivFour {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivFive {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivSix {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivSeven {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivEight {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivNine {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }

  .PrevSidebarButtonWrapTwo {
    max-height: 49vh;
  }
  .PrevSidebarButtonWrapOne {
    max-height: 49vh;
  }
}
@media (max-width: 480px) {
  .PreviouProjectDivOne {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivTwo {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivThree {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivFour {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivFive {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivSix {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivSeven {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivEight {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PreviouProjectDivNine {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    min-height: 375px;
    max-height: 375px;
  }
  .PrevSidebarButtonWrapTwo {
    max-height: 49vh;
  }
  .PrevSidebarButtonWrapOne {
    max-height: 49vh;
  }

  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 1.5s;
    transition: transform 1.5s;
    transition: transform 1.5s, -webkit-transform 1.5s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    background-color: #fff;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

@media (max-width: 480px) {
  .card-container {
    cursor: pointer;
    height: 320px;
    width: 270px;
    -webkit-perspective: 600;
            perspective: 600;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  /*card to fit the size of the card container by giving the width and height of 100% and position of absolute which is relative to the card container*/
  .card {
    height: 100%;
    width: 100%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: absolute;
    transition: all 1s ease-in-out;
  }
  /*card rotate from y axis on hover*/
  .card:hover {
    -webkit-transform: rotatey(180deg);
            transform: rotatey(180deg);
  }
  /*Front side of the card with the property of backface visibility to make the other side of card hidden*/
  .side {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 6px;
    background-color: white;
  }
  /*back side of the card*/
  .back {
    -webkit-transform: rotatey(180deg);
            transform: rotatey(180deg);
    background-color: white;
  }
}

.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  /* transition: transform 0.9s; */
  transition: -webkit-transform 1.8s ease-in-out;
  transition: transform 1.8s ease-in-out;
  transition: transform 1.8s ease-in-out, -webkit-transform 1.8s ease-in-out;
  -webkit-transform: translate3d(-3592px, 0px, 0px);
          transform: translate3d(-3592px, 0px, 0px);
  /* transition: all 0s ease 0s; */
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
  color: #fff;
  width: 100vw;
  overflow-y: hidden;
}

/* .HomeCarousel {
  padding: relative;
} */

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  background-color: black;
  color: #fff;
}

.CaaroButtonBoxStyle {
  width: 100%;
  max-width: 800px;
  position: absolute;
  top: 80%;
  right: 36%;
  background: transparent;
  height: auto;
  border: none;
}


@media screen and (min-width: 900px) and (max-width: 999px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 85%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}


@media screen and (min-width: 1000px) and (max-width: 1099px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}



@media screen and (min-width: 1100px) and (max-width: 1366px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}


@media screen and (min-width: 1367px) and (max-width: 1499px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 80%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 85%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1950px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 65%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 12%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 15%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2500px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: yellow; */
    /* 75% */
  }
}


@media screen and (min-width: 2501px) and (max-width: 2600px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: yellow; */
    /* 80% */
  }
}


@media screen and (min-width: 2601px) and (max-width: 2800px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 21%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: red; */
  }
}





@media screen and (min-width: 2801px) and (max-width: 3000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* background-color: green; */
    position: absolute;
    top:48%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right:22%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: aqua; */
    /* 67% */
  }
}


@media screen and (min-width: 3201px) and (max-width:4000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top:36%;
    /* background-color: maroon; */
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right:30%;
    /* background: transparent; */
    height: auto;
    border: none;
    /* 50% */
  }
}

@media screen and (min-width: 4001px) and (max-width:6000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top:25%;
    /* background-color: maroon; */
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right:37%;
    /* background: red; */
    height: auto;
    border: none;
  }
}



@media screen and (min-width: 6001px) and (max-width:8000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top:18%;
    /* background-color: maroon; */
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right:40%;
    /* background: yellow; */
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 5001px) and (max-width:8000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top:15%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right:60%;
    /* background: transparent; */
    height: auto;
    /* background-color: red; */
    border: none;
  }
}

.CLientSayText {
  font-family: "Playfair Display";
}

.slick-dots li.slick-active button {
  /* font-size: -5px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: black;
  color: black;
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}
.slick-dots li.slick-active button::before {
  /* font-size: 15px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: black;
  /* color: black; */
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}
.slick-dots li.slick-active button::before {
  /* font-size: 10px; */
  height: 0px;
  width: 22px;
  /* line-height: 10px; */
  background-color: black;
  color: black;
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}
/* .slick-dots li button:before */
.slick-dots li button {
  height: 0px;
  width: 22px;
  /* line-height: 10px; */
  /* font-size: 10px; */
  /* color: lightblue; */
  background-color: #f9f9f9;
  /* font-size: 0px; */
  /* border: 0.2px black; */
  /* margin-bottom: 5px; */
}
.slick-dots li button::before {
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  /* font-size: 15px; */
  /* color: lightblue; */
  background-color: #f9f9f9;
  font-size: 0px;
  /* border: 0.2px black; */
  /* margin-bottom: 5px; */
}
/* ul .slick-dots {
  list-style-type: square;
} */
.slick-dots.slick-thumb {
  margin-top: 50px;
}
.slick-dots {
  bottom: -114px;
}
.HeroSidebarButtonOne {
  height: 100%;
}
.HeroSidebarButtonTwo {
  height: 100%;
}
.slick-arrow.slick-next::before,
.slick-arrow.slick-prev::before {
  display: none;
  /* font-size: 0px; */
}

.slick-dotsCaro {
  /* font-size: -5px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: transparent;
  color: transparent;
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}

.slick-dotsCaro li.slick-active button {
  /* font-size: -5px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: black;
  color: black;
  /* border-radius: 0px; */
  border: none;
}
.slick-dotsCaro li.slick-active button::before {
  /* font-size: 15px; */
  height: 0px;
  width: 22px;
  /* line-height: 15px; */
  background-color: black;
  /* color: black; */
  /* border-radius: 0px; */
  /* border: 0.2px black; */
}

.slick-dotsCaro li button {
  height: 0px;
  width: 22px;
  /* line-height: 10px; */
  /* font-size: 10px; */
  /* color: lightblue; */
  background-color: #f9f9f9;
  /* font-size: 0px; */
  /* border: 0.2px black; */
  /* margin-bottom: 5px; */
}
.slick-dotsCaro li button::before {
  height: 0px;
  width: 22px;
  background-color: #f9f9f9;
  font-size: 0px;
}

.slick-dotsCaro .slick-thumb-caro {
  margin-top: 50px;
}

.slick-dotsCaro {
  bottom: -114px;
}

.custom-indicatornewcaro {
  position: absolute;
  top: 98%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  /* filter: grayscale(100%); */
}

.custom-indicatornewcaro li {
  height: 2px;
  width: 12px;
  max-width: 20px;
  margin-left: auto;
  margin-right: auto;
  /* border-top: 3px solid #FFFFFF; */
  padding-top: 1;
  padding-left: auto;
  padding-right: auto;
  border: none;
  background-color: rgb(222, 222, 222);
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  /* background-color: transparent; */
}
.custom-indicatornewcaro li.slick-active {
  /* filter: grayscale(0%); */
  border: none;
  background-color: gray;
  height: 2px;
  width: 12px;
  max-width: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* new */

.custom-indicatornewcaro1 {
  position: absolute;
  top: 105%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  /* filter: grayscale(100%); */
}

.custom-indicatornewcaro1 li {
  height: 2px;
  width: 12px;
  max-width: 20px;
  margin-left: auto;
  margin-right: auto;
  /* border-top: 3px solid #FFFFFF; */
  padding-top: 1;
  padding-left: auto;
  padding-right: auto;
  border: none;
  background-color: rgb(222, 222, 222);
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  /* background-color: transparent; */
}
.custom-indicatornewcaro1 li.slick-active {
  /* filter: grayscale(0%); */
  border: none;
  background-color: gray;
  height: 2px;
  width: 12px;
  max-width: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1024px) {
  .custom-indicatornewcaro {
    top: 105%;
  }
}
@media (max-width: 900px) {
  .HeroSidebarButtonOne {
    height: 100%;
  }
  .HeroSidebarButtonTwo {
    height: 100%;
  }
}
@media (max-width: 768px) {
  .custom-indicatornewcaro {
    top: 109%;
  }
  .HeroSidebarButtonOne {
    font-size: 10px;
  }
  .HeroSidebarButtonTwo {
    font-size: 10px;
  }
}
@media (max-width: 480px) {
  .custom-indicatornewcaro {
    top: 112%;
  }
  .HeroSidebarButtonOne {
    height: 100%;
  }
  .HeroSidebarButtonTwo {
    height: 100%;
  }
}

.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  /* transition: transform 1.2s; */
  transition: -webkit-transform 1.8s ease-in-out;
  transition: transform 1.8s ease-in-out;
  transition: transform 1.8s ease-in-out, -webkit-transform 1.8s ease-in-out;
  -webkit-transform: translate3d(-3592px, 0px, 0px);
          transform: translate3d(-3592px, 0px, 0px);
  /* transition: all 0s ease 0s; */
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
  color: #fff;
  width: 100vw;
  overflow-y: hidden;
}

/* .HomeCarousel {
  padding: relative;
} */

.SidebarButtonOne {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: all 2s ease-in-out;
}

.SidebarButtonWrapTwo {
  width: 100%;
  max-width: 70px;
  height: 100%;
  max-height: 600px;
  position: absolute;
  top: 0;
  border: none;
  right: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
}

.SidebarButtonWrapOne {
  width: 100%;
  max-width: 70px;
  height: 100%;
  max-height: 600px;
  position: absolute;
  top: 0;
  border: none;
  left: 0;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
}

.SidebarButtonOne {
  display: block;
  opacity: 1;
}

.SidebarButtonTwo {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: all 2s ease-in-out;
}

.SidebarButtonTwo {
  opacity: 1;
}

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  background-color: black;
  color: #fff;
}

.CaaroButtonBoxStyle {
  width: 100%;
  max-width: 800px;
  position: absolute;
  top: 80%;
  right: 36%;
  background: transparent;
  height: auto;
  border: none;
}

@media screen and (min-width: 900px) and (max-width: 999px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 85%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1366px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1499px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 80%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 85%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1950px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 65%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 12%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 15%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2500px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: yellow; */
    /* 75% */
  }
}

@media screen and (min-width: 2501px) and (max-width: 2600px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: yellow; */
    /* 80% */
  }
}

@media screen and (min-width: 2601px) and (max-width: 2800px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 21%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: red; */
  }
}

@media screen and (min-width: 2801px) and (max-width: 3000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    /* background-color: green; */
    position: absolute;
    top: 48%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 22%;
    background: transparent;
    height: auto;
    border: none;
    /* background-color: aqua; */
    /* 67% */
  }
}

@media screen and (min-width: 3201px) and (max-width: 4000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 36%;
    /* background-color: maroon; */
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 30%;
    /* background: transparent; */
    height: auto;
    border: none;
    /* 50% */
  }
}

@media screen and (min-width: 4001px) and (max-width: 6000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 25%;
    /* background-color: maroon; */
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 37%;
    /* background: red; */
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 6001px) and (max-width: 8000px) {
  .CaaroButtonBoxStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 18%;
    /* background-color: maroon; */
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 40%;
    /* background: yellow; */
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 5001px) and (max-width: 8000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 15%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 60%;
    /* background: transparent; */
    height: auto;
    /* background-color: red; */
    border: none;
  }
}

.MobileheroSectionMedhanshLogo {
  width: 40%;
  height: auto;
  position: absolute;
  top: 80%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.MobileheroSectionAlloaLogo {
  width: 20%;
  height: auto;
  position: absolute;
  top: 80%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.MobileheroSectionInverLogo {
  width: 40%;
  height: auto;
  position: absolute;
  top: 80%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media only screen and (min-width: 0px) and (max-width: 480px) {
  .MobileheroSectionAlloaLogo {
    width: 40%;
    top: 70%;
    left: 45%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .MobileheroSectionMedhanshLogo {
    width: 40%;
    top: 70%;
    left: 55%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .MobileheroSectionInverLogo {
    width: 40%;
    top: 80%;
    left: 55%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

@media (max-width: 900px) {
  .MobileheroSectionAlloaLogo {
    width: 40%;
    top: 70%;
    left: 45%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .MobileheroSectionMedhanshLogo {
    width: 20%;
    top: 70%;
    left: 55%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .MobileheroSectionInverLogo {
    width: 40%;
    top: 80%;
    left: 55%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  background: #fcfcfc;
  width: 47px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.carousel .slick-prev {
  left: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.carousel .slick-next {
  right: 0;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}

.custom-indicatornew {
  position: absolute;
  top: 86%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  /* height: 110px; */
  /* filter: grayscale(100%); */
}

.InformationBoxThree {
  margin-top: -420px;
  margin-left: auto;
  position: relative;
  margin-right: 50px;
  width: 100%;
  max-width: 400px;
  text-align: right;
  height: 100%;
  opacity: 1;
  text-align: center;
  z-index: 10;
}

.custom-indicatornewHome {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  min-height: 250px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  min-height: 5px;
  max-height: 5px;
  cursor: pointer;
}
.custom-indicatornew li {
  height: 100%;
  width: 75%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  /* border-top: 3px solid #FFFFFF; */
  padding-top: 1;
  padding-left: auto;
  padding-right: auto;
  border: none;
  cursor: pointer;

  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  background-color: transparent;
}

.custom-indicatornew li.slick-active {
  /* filter: grayscale(0%); */
  border: none;
  background-color: transparent;
  height: 50px;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.multi__image:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 500ms;
}

.slick-dotnewnew li button {
  height: 0px;
  width: 100%;
  max-width: 250px;
  border: none;
  /* border-top: 3px solid rgb(212, 212, 212); */
  background-color: rgb(213, 205, 205);
  color: rgb(213, 205, 205);
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-top: 25%;
  /* font-size: 15px; */
}

.slick-dotnewnew li.slick-active button {
  height: 0px;
  width: 100%;
  max-width: 200px;
  border: none;
  /* border-top: 3px solid rgb(12, 8, 9); */
  background-color: rgb(92, 88, 88);
  color: rgb(92, 88, 88);
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  /* font-size: 15px; */
}

.slick-dotnew li.slick-active button {
  height: 50px;
  width: 100%;
  max-width: 200px;
  border-top: 3px solid #fff;
  background-color: transparent;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
}

.slick-dotnew li button {
  height: 50px;
  width: 90%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background-color: transparent;
  color: white;
  border-top: 3px solid rgb(255, 255, 255);
  cursor: pointer;
  color: gray;
  font-size: 15px;
}
.slick-dotnew li button:hover {
  border-top: 3px solid #fff;
  background-color: transparent;
  color: #fff;
}
.slick-dotnew.slick-thumb {
  margin-top: 50px;
}
.slick-dotnew {
  /* bottom: -114px; */
}

/* new */

.slick-dotnewnew1 li button {
  height: 0px;
  width: 100%;
  max-width: 250px;
  border: none;
  /* border-top: 3px solid rgb(212, 212, 212); */
  background-color: rgb(213, 205, 205);
  color: rgb(213, 205, 205);
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  /* font-size: 15px; */
}

.slick-dotnewnew1 li.slick-active button {
  height: 0px;
  width: 100%;
  max-width: 200px;
  border: none;
  /* border-top: 3px solid rgb(12, 8, 9); */
  /* background-color: rgb(92, 88, 88); */
  /* color: rgb(92, 88, 88); */
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  /* font-size: 15px; */
}

.slick-dotnew1 li.slick-active button {
  height: 50px;
  width: 100%;
  max-width: 200px;
  /* border-top: 3px solid #fff; */
  background-color: transparent;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
}

.slick-dotnew1 li button {
  height: 50px;
  width: 90%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background-color: transparent;
  color: white;
  /* border-top: 3px solid gray; */
  cursor: pointer;
  color: gray;
  font-size: 15px;
}
.slick-dotnew1 li button:hover {
  /* border-top: 3px solid #fff; */
  background-color: transparent;
  color: #fff;
}
.slick-dotnew.slick-thumb {
  margin-top: 50px;
}
/* .slick-dotnew {
  bottom: -114px;
} */

@media (max-width: 600px) {
  .custom-indicatornew {
    top: 100%;
  }
  .custom-indicatornewHome {
    min-width: 220px;
    max-width: 220px;
  }
}
@media (max-width: 480px) {
  .custom-indicatornewHome {
    min-width: 220px;
    max-width: 220px;
  }
}

/* @media (min-width: 900px) and (max-width: 980px) {
  .audiomusic{
    margin-left:5px;
  }
} */

audio {
  width: 100px;
  height: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
  /* background: red !important; */
}

audio::-webkit-media-controls-mute-button {
  display: none !important;
  background: transparent !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
  background: transparent !important;
}

audio::-webkit-media-controls-volume-control-container.closed {
  display: none !important;
  background: transparent !important;
}
audio::-webkit-media-controls-volume-control-container {
  display: none !important;
}

.dropdown {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  opacity: 1;
  position: relative;
  font-size: 18px;
  background: transparent;
  z-index: 100;
  width: 50% !important;
  max-width: 300px !important;
}
.dropdown:hover {
  cursor: pointer;
}

.dropdown_menu {
  width: auto;
  position: absolute;
  /* top: 76%; */
  left: -120%;
  width: 100%;
} 
.dropdown_menu li {
  background: rgba(249, 249, 249, 0.3);
  padding: 10px 0px;
  width: 200px;
  font-size: 16px;
}
.dropdown_menu li > p {
  color: #dedbdb;
}
.dropdown_menu li:hover > p {
  color: #fff;
}

.dropdown:hover .dropdown_menu {
  opacity: 1;
  top: 76%;
  visibility: visible;
}

.dropdown_item:hover .dropdown_menu_hover_two {
  opacity: 1;
  visibility: visible;
}


.dropdown_menu_home {
  width: auto;
  position: absolute;
  left: -120% !important;
  width: 200px;
  max-width: 250px;
  text-align: center;
}

.dropdown_item_two {
  width: auto;
  position: absolute;
  left: 235px;
  right: 0%;
  top: 100%;
  width: 220px;
  padding: 0;
  opacity: 0;
  transition: all 1.5s ease;
}

.dropdown_item_three {
  width: auto;
  position: absolute;
  left: 240px;
  right: 0%;
  top: 200%;
  width: 200px;
  padding: 0;
  opacity: 0;
  transition: all 1.5s ease;
}

.dropdown_menu_home_three {
  width: auto;
  position: absolute;
  left: -120%;
  width: 100%;
}

.dropdown_menu_home li {
  background: rgba(249, 249, 249, 0.3);
  padding: 10px 0px;
  width: 200px;
  font-size: 16px;
}

.dropdown_menu_home_three li {
  background: rgba(249, 249, 249, 0.3);
  padding: 10px 0px;
  width: 200px;
  font-size: 16px;
}

.dropdown_menu_home_two li {
  background: rgba(249, 249, 249, 0.3);
  padding: 10px 0px;
  width: 200px;
  font-size: 16px;
}

.dropdown_menu_home li > p {
  color: #f9f9f3;
  opacity: 0.7;
}

.dropdown_menu_home_two li > p {
  color: #f9f9f3;
  opacity: 0.7;
}

.dropdown_menu_home_three li > p {
  color: #f9f9f3;
  opacity: 0.7;
}
.dropdown_menu_home li:hover > p {
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.dropdown_menu_home_two li:hover > p {
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.dropdown_menu_home_three li:hover > p {
  color: #fff;
  opacity: 1;
  font-size: 15px;
}
.dropdown:hover .dropdown_menu_home {
  opacity: 1;
  top: 76%;
  visibility: visible;
}

.DropdownOne:hover .dropdown_item_two {
  opacity: 1;
  top: 0%;
  visibility: visible;
  transition: all 1.5s ease;
}

.DropdownTwo:hover .dropdown_item_three {
  opacity: 1;
  top: 0%;
  visibility: visible;
  transition: all 1.5s ease;
}

.dropdown_menu_home {
  transition: all 600ms ease;
  top: 90%;
  opacity: 0;
  visibility: hidden;
}

.dropdown_menu_hover_two {
  transition: all 1s ease;
  top: 90%;
  opacity: 0;
  visibility: hidden;
}

.dropdown_menu_hover_three {
  transition: all 1s ease;
  top: 90%;
  opacity: 0;
  visibility: hidden;
}

.NavbarStyle {
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 1.09%;
  background: #fff;
  border-radius: 0px;
  height: 80px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  transition: all 1.5s ease;
  opacity: 0;
  visibility: hidden;
}

.NewNavbarStyle {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 1.09%;
  background: transparent;
  border-radius: 0px;
  height: 80px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
  visibility: visible;
  border-bottom: 0.5px solid rgb(175, 175, 175);
}

.NewNavbarStyleUnActive {
  opacity: 0;
  visibility: hidden;
}

.NavbarStyleActive {
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 1.09%;
  background: #fff;
  border-radius: 0px;
  height: 80px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s ease;
  opacity: 1;
  visibility: visible;
}

@-webkit-keyframes slidenav {
  from {
    top: -10%;
  }
  to {
    top: 0%;
  }
}

@keyframes slidenav {
  from {
    top: -10%;
  }
  to {
    top: 0%;
  }
}

li {
  list-style: none;
}

@media screen and (max-width: 899px) {
  .NavbarStyle {
    display: none;
  }
  .NavbarStyleActive {
    display: none;
  }
  .NewNavbarStyle {
    display: none;
  }
  .NewNavbarStyleUnActive {
    display: none;
  }
}

.newDropDownText {
  font-family: "Playfair Display";
  font-style: "normal";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: "center";
  color: rgb(218, 216, 216);
  background-color: transparent;
  border: none;
}

.Dropdowntextheader {
  font-family: Playfair Display;
  font-style: "normal";
  font-weight: 400;
  font-size: "14px";
  line-height: "20px";
  text-align: "center";
  color: #000;
  background-color: #fff;
  border: none;
  opacity: 0.8;
}
.Dropdowntextheader:hover {
  color: #000;
  font-weight: 600;
  cursor: pointer;
  font-size: "16px";
  opacity: 1;
}

.newDropDownText:hover {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: "16px";
}


@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuramediumbt.ee64fb9d.ttf) format("truetype");
  font-weight: normal;
}
.about-us-bg-image {
  width: 100%;
  height: 400px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/thecompany.45b728e5.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.HistorytextBox {
  height: 520px;
  font-size: 12px;
  line-height: 4px;
}

.messageStyle {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #333334;
  text-align: Justify;
  margin-bottom: 0px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.messageStyle1 {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  text-align: Justify;
  margin-bottom: 7px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  padding: 10px;
  margin-top: -50px;
}
.HistorySpan {
  font-family: "Playfair Display";
  font-weight: 600;
  color: black;
  font-size: 42px;
}

.textStyleOfChairMainMsg {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.MainTextStyl {
  font-family: Playfair Display;
  font-size: 42px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 1;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  text-align: left;
  margin-top: 10px;
}
.titleStyle {
  font-family: "Futura Md BT";
  font-size: 28px;
  margin-top: 16px;
}

.visionmissiontext {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #979797;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.textStyleOfChairMainMsg,
.textStyleOfChairMainMsg span {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.textStyleOfChairMainMsg span {
  display: inline-block;
}

.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
          animation-name: fadeInBottom;
}
.img-mission {
  background-color: "red";
}
@-webkit-keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
.HistorySpan {
  font-weight: 600;
  color: black;
  font-size: 42px;
}
.AboutHerosectionBox {
  width: 100%;
  height: 530px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
@media (max-width: 768px) {
  .textStyleOfChairMainMsg {
    font-size: 16px;
    line-height: 25px;
  }
  .visionmissiontext {
    font-size: 16px;
    line-height: 25px;
  }
}

.imgSize {
  height: 558px;
}
.OurHistoryTextStyle {
  line-height: 25px;
}

@media (max-width: 1400px) {
  .imgSize {
    height: 100%;
    width: 95%;
  }
}
@media (max-width: 1360px) {
  .imgSize {
    height: 600px;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .HistorytextBox {
    height: 520px;
    font-size: 12px;
    line-height: 4px;
    overflow: auto;
  }

  .imgSize {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .imgSize {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 380px) {
  .HistorySpan {
    margin-top: 25px;
  }
}

@media (max-width: 900px) {
  .vm-responsive {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("/static/media/futura medium condensed bt.bf7355ba.ttf")
      format("truetype");
  font-weight: normal;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  width: 120%;
  border: 0.5px solid #868686;
}

.MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.css-1xc9rp1-MuiInputBase-root-MuiInput-root {
  border: 1px solid #f1e9e9;
}

textarea {
  font: inherit;
  resize: "none";
  letter-spacing: inherit;
  color: currentColor;
  width: 98%;
}

.InputBoxstyle {
  width: "100%";
  max-width: "235px";
  margin-left: "auto";
  margin-right: "auto";
  height: "50px";
  border: "1px solid #F4F4F4";
  border-radius: " 0px";
  padding-left: "10px";
  margin-top: "15px";
  font-style: "normal";
  font-weight: 400;
  font-size: "16px";
  line-height: "25px";
  text-align: "left";
  letter-spacing: "0.05em";
  color: "#868686";
}

.contact-us-bg-image {
  width: 100%;
  height: 380px;
  background: url(/static/media/Rectangle-1.ec14e90c.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 1500px) {
  .LastInputBoxStyle {
    width: 98%;
  }
}

.InputBoxStyle1 {
  width: 92%;
  max-width: 250px;
  margin-top: 18px;
  height: 55px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  align-items: left;
  -webkit-appearance: none;
  appearance: none;
}
.InputBoxStyle2 {
  width: 92%;
  max-width: 250px;
  margin-top: 18px;
  height: 55px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  align-items: right;
  -webkit-appearance: none;
  appearance: none;
}

.ContactInputMsgBox {
  width: 95%;
  max-width: 530px;
  margin-top: 18px;
  height: 55px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  -webkit-appearance: none;
  appearance: none;
}

.LastInputBoxStyle {
  width: 93%;
  max-width: 550px;
  margin-top: 18px;
  height: 160px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  -webkit-appearance: none;
  appearance: none;
}

@media only screen and (min-width: 0px) and (max-width: 400px) {
  .ContactInputMsgBox {
    width: 99%;
    max-width: 340px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle1 {
    width: 100%;
    max-width: 340px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 100%;
    max-width: 340px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 94%;
    max-width: 340px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
  .ContactInputMsgBox {
    width: 99%;
    max-width: 350px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle1 {
    width: 100%;
    max-width: 350px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 100%;
    max-width: 350px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 94%;
    max-width: 350px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .ContactInputMsgBox {
    width: 99%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle1 {
    width: 100%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 100%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 94%;
    max-width: 400px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .InputBoxStyle1 {
    width: 85%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 85%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  /* .LastInputBoxStyle {
    width: 93%;
    max-width: 400px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  } */
}

@media screen and (min-width: 1201px) and (max-width: 2000px) {
  .InputBoxStyle1 {
    width: 90%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 90%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 95%;
  }
}

.fooimg {
  width: "30px" !important;
  height: "30px" !important;
}

@media (max-width: 380px) {
  .fooimg {
    width: "20px" !important;
    height: "20px" !important;
  }
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuramediumbt.ee64fb9d.ttf) format("truetype");
  font-weight: normal;
}

.InvernessIntroText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #808186;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.heroImgInver {
  /* background-image: url(../../assets/Images/HomeCarouselImages/Inverness.png); */
  background:
   /* linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), */ url(/static/media/14_Wood_house_SectorB.6ab6abdf.jpg);

  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  position: relative;
  z-index: 0;

  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  -webkit-animation: zoomhome 30s infinite alternate;
          animation: zoomhome 30s infinite alternate;
  background-position: center;
}
.MuiGrid-root.MuiGrid-container.css-1k888q6-MuiGrid-root {
  max-width: 357px;
}

.InvernessCaroImgOne {
  background-image: url(/static/media/InvernessB.6e96ed54.png);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.InvernessCaroImgTwo {
  background-image: url(/static/media/InImgTwo.dc0fa435.jpeg);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.InvernessCaroImgThree {
  background-image: url(/static/media/InImgThree.0f99b67a.jpeg);
  width: 95%;
  height: 100%;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.InvernessCaroImgFour {
  background-image: url(/static/media/InImgSeven.150c986b.jpeg);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.img-zoom:hover {
  background-size: 110% 110%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}

.img-filter {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  -webkit-filter: grayscale(0%);

          filter: grayscale(0%);
}

.OurPartnerText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: center;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.AmentiseText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #bababa;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.visionmissiontext {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #979797;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.InvernessIntroText,
.InvernessIntroText span {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  font-family: "Playfair Display";
}

.OurPartnerText,
.OurPartnerText span {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.OurPartnerText span {
  display: inline-block;
}

.InvernessIntroText span {
  display: inline-block;
}

.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
          animation-name: fadeInBottom;
}

.LinkStyleText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #5392bf;
  text-align: Justify;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  text-align: center;
}

@-webkit-keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
.ActiveButton1 {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
}

.ActiveButton1:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.ActiveButton1::before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

.ActiveButton1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.UnActiveText1 {
  font-size: 25px;
  width: auto;
  height: 80px;
  position: relative;
}
.ActiveButton1:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  cursor: pointer;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.UnActiveText1:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.InvernessIntroTextTitle {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: #252733;
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
.invernessLogo {
  height: auto;
  min-width: 300px;
  width: 30%;
  margin-left: auto;
  margin-right: 0px;
  margin-top: 2px;
  margin-bottom: 20px;
}
@media screen and (min-width: 381px) and (max-width: 480px) {
  .invernessLogo {
    height: auto;
    /* background-color: blue; */
    width: 55%;
    margin-top: 50px;
  }

  /* margin-left:100px; */
  /* margin-left:auto; */
  /* margin-right: auto; */
  /* position: absolute; */
  /* right:40%; */
  /* .audioSetting{
    margin-top: 2px;
    margin-left: "5px";
  } */
  .heroImgInver {
    background:
     /* linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), */ url(/static/media/14_Wood_house_SectorB.6ab6abdf.jpg);
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 0;

    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    -webkit-animation: zoomhome 30s infinite alternate;
            animation: zoomhome 30s infinite alternate;
    background-position: center;
  }
}
@media screen and (min-width: 0px) and (max-width: 380px) {
  .invernessLogo {
    height: auto;
    width: 30%;
    /* background-color: red; */
    margin-top: 50px;
  }
  /* position: absolute; */
  /* right:35%; */
  /* margin-left:50px;
    margin-right: auto */
  /* .audioSetting{
    margin-top: 3px;
    margin-left:"5px"
   
  } */
  .heroImgInver {
    background: 
    /* linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), */ url(/static/media/14_Wood_house_SectorB.6ab6abdf.jpg);
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 0;

    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    -webkit-animation: zoomhome 30s infinite alternate;
            animation: zoomhome 30s infinite alternate;
    background-position: center;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .InvernessIntroText {
    font-size: 14px;
    line-height: 25px;
  }
  .visionmissiontext {
    font-size: 16px;
    line-height: 25px;
  }
  .OurPartnerText {
    font-size: 14px;
    line-height: 25px;
  }
  .InvernessCaroImgOne {
    height: 380px;
  }
  /* .invernessLogo {
    height: auto;
    width: 45%;
    margin-top: 50px;
  } */
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .InvernessIntroText {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1367px) {
  .HerosectionButtonStyle {
    width: 80%;
    max-width: 800px;
    /* position: absolute; */
    top: 80%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
  .InvernessCaroImgOne {
    height: 500px;
  }
  .invernessLogo {
    height: auto;
    width: 45%;
    margin-top: 50px;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1499px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 80%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 85%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1950px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 65%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 12%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 15%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2601px) and (max-width: 3200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 48%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 21%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3201px) and (max-width: 3400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 38%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 28%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3401px) and (max-width: 4000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3501px) and (max-width: 6000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 6001px) and (max-width: 10000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 18%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 40%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media (min-height: 450px) and (max-width: 900px) {
  .InvernessCaroImgThree {
    height: 100%;
    min-height: 450px;
    width: 100%;
  }
}

@media (min-width: 901px) and (max-width: 1100px) {
  .InvernessCaroImgThree {
    height: 100%;
    min-height: 450px;
    max-height: 450px;
    width: 100%;
  }
}

@media (min-height: 0px) and (max-width: 449px) {
  .InvernessCaroImgThree {
    height: 100%;
    min-height: 300px;
    width: 100%;
  }
}

.img-filter {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  -webkit-filter: grayscale(0%);

          filter: grayscale(0%);
}

.img-zoom:hover {
  background-size: 100% 80%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}
.InOneImg {
  width: 98%;
  height: auto;
  min-height: 325px;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/1_Club_house_Secor_A_new.01279ef7.jpg);
}

.InTwoImg {
  width: 98%;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/3_Garden_Sector_A_new.6e2cc8a9.jpg);
}

.InThreeImg {
  margin-top: 5px;
  width: 98%;
  max-width: 98%;
  overflow: hidden;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/6_Sample_House_new.dcf09406.jpg);
}

.InFourImg {
  margin-top: 5px;
  width: 98%;
  max-width: 98%;
  overflow: hidden;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/10_Sample_House.4ed18c4c.jpg);
}

.InFiveImg {
  margin-bottom: 5px;
  width: 98%;
  min-height: 325px;
  height: 90%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/12_Sample_House_new.9ec33573.jpg);
}

.InSixImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/18_Garden_Sectoe_A_new.6e713783.jpg);
}

.InSevenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/19_Club_house_Secor_A_new.358878ae.jpg);
}

.InEightImg {
  width: 98%;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/21_Club_house_Secor_A_new.6082a0d6.jpg);
}

.InNineImg {
  width: 98%;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/22_Sample_house.737d1979.jpg);
}
.InTenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/14_Wood_house_SectorB.f5a6a7ac.jpg);
}
.InElevenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/WhatsApp11.0f46fd08.jpeg);
}
.InTweleveImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/consupdate12.88e96bc7.jpg);
}

.InThirteenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/view1.2f6d9472.jpeg);
}

.InForteenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/view2.0de35894.jpeg);
}

.InFifteenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/view3.9ead88dd.jpeg);
}

.InSixteenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/view4.4586a8d2.jpeg);
}

.InseventeenImg {
  width: 98%;

  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/view5.8ac61813.jpeg);
}

.InEightteenImg {
  width: 98%;
  min-height: 325px;
  height: 100%;
  max-height: 325px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/view6.0f99b67a.jpeg);
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .InOneImg {
    height: 280px;
  }
  .InTwoImg {
    height: 280px;
  }
  .InThreeImg {
    height: 280px;
  }
  .InFourImg {
    height: 280px;
  }
  .InFiveImg {
    height: 280px;
  }
  .InSixImg {
    height: 280px;
  }
  .InSevenImg {
    height: 280px;
  }
  .InEightImg {
    height: 280px;
  }
  .InNineImg {
    height: 280px;
  }
  .InTenImg {
    height: 280px;
  }
  .InElevenImg {
    height: 280px;
  }
  .InTweleveImg {
    height: 280px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1100px) {
  .InOneImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTwoImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InThreeImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InFourImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InFiveImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InSixImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InSevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InEightImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InNineImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InElevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTweleveImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InThirteenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InForteenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InFifteenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InSixteenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InseventeenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InEightteenImg {
    min-height: 250px;
    max-height: 250px;
  }
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .InOneImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InTwoImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InThreeImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InFourImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InFiveImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InSixImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InSevenImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InEightImg {
    min-height: 250px;
    max-height: 250px;
  }

  .InNineImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InElevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .InTweleveImg {
    min-height: 250px;
    max-height: 250px;
  }
}
/* @media (max-width: 480px){
    .InOneImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InTwoImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InThreeImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InFourImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InFiveImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InSixImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InSevenImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InEightImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InNineImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InTenImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InElevenImg {
      max-height: 350px;
      min-height: 350px;
    }
    .InTweleveImg {
      max-height: 350px;
      min-height: 350px;
    }
  }
   */

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuramediumbt.ee64fb9d.ttf) format("truetype");
  font-weight: normal;
}

.InvernessIntroText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.4);
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.HpaIntroTExt {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.OurPartnerText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: center;

  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.ourArchitecText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #808186;
  text-align: center;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.AmentiseText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #bababa;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.visionmissiontext {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #979797;
  text-align: Justify;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.ourArchitecText,
.ourArchitecText span {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.InvernessIntroText,
.InvernessIntroText span {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.OurPartnerText,
.OurPartnerText span {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.ourArchitecText span {
  display: block;
}

.OurPartnerText span {
  display: inline-block;
}

.InvernessIntroText span {
  display: inline-block;
}

.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
          animation-name: fadeInBottom;
}

.LinkStyleText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #5392bf;
  text-align: Justify;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  text-align: center;
}

.Amentise-Div-Image {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/Amentise.18b5b5e1.png);
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 450px;
  max-height: 100vh;
  overflow: hidden;
  max-width: 100%;
  /* background-color: red; */
  max-width: auto;
  overflow: hidden;
  transition: all 4s ease-in-out;
  -webkit-animation: imageSyle 5s ease-in-out;
          animation: imageSyle 5s ease-in-out;
}

.AllohaIntroImgOne {
  background-image: url("/static/media/m (1).cfe8cf0a.jpg");
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.AllohaIntroImgTwo {
  background-image: url(/static/media/inverness_360newImgcropped.8dd2b00c.jpg);
  width: 100%;
  height: 100%;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.AllohaIntroImgThree {
  background-image: url("/static/media/m (2).88d035b8.jpg");

  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.MedhanshIntroImgOne {
  background-image: url(/static/media/Medhaansh.57fa8644.png);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.MedhanshIntroImgOne {
  background-image: url(/static/media/Medhaansh.57fa8644.png);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.MedhanshIntroImgTwo {
  background-image: url(/static/media/Rect6.f5bd6a73.jpg);
  width: 98%;
  height: 100%;
  min-height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.MedhanshIntroImgTree {
  background-image: url(/static/media/Rect5.6e7f3608.png);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.ImgLogoMed {
  height: auto;
  width: 30%;
  margin-left: auto;
  margin-right: 0px;
  margin-top: 2px;
  margin-bottom: 20px;
}
@-webkit-keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .visionmissiontext {
    font-size: 16px;
    line-height: 25px;
  }
  .OurPartnerText {
    font-size: 14px;
    line-height: 25px;
  }
  .ImgLogoMed {
    height: auto;
    width: 300px;
    min-width: 50% !important;
    /* background-color: red; */
  }
}
@media (max-width: 480px) {
  .ImgLogoMed {
    height: auto;
    width: 300px;
    margin-top: 50px;
  }
  /* .audioSetting{
    margin-top: 2px;
    margin-left:110px;
    margin-right: auto
  } */
}

/* @media screen and (max-width: 600px){
  .gal-img{
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 480px){
  .gal-img{
    margin-bottom: 20px;
  }
} */

.cssanimation,
.cssanimation span {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
          animation-name: fadeInBottom;
}
.heroImgMed {
  /* background-image: url(../../assets/Images/HomeCarouselImages/Inverness.png); */
  background:
   /* linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), */ url(/static/media/Rect8.cbe91683.png);
  height: 100vh;
  max-width: 100%;
  width: 100%;
  position: relative;
  width: "100%";
  z-index: 0;

  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  -webkit-animation: zoomhome 30s infinite alternate;
          animation: zoomhome 30s infinite alternate;
  /* overflow-x: hidden; */
  background-position: center;

  /* height: "100%",  
  minHeight: "800px",
  maxHeight: "100vh",
  position: "relative",
  background: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url(${AllohaImg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover" */
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

.SpecificationTextStyle {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  text-transform: uppercase;

  color: #333333;
}

.HerosectionButtonStyle {
  width: 100%;
  max-width: 800px;
  position: absolute;
  top: 10%;
  /* bottom: 0%; */

  /* transform: translateY(-50%); */
  right: 10%;
  background: transparent;
  height: auto;
  border: none;
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
  .InvernessIntroText {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.4);
    text-align: Justify;
    margin-bottom: 20px;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 370px;
    max-height: 370px;
    margin-top: 50px;
    width: 100%;
  }
  .AllohaIntroImgTwo{
    height: 300px;
    max-height: 300px;
    margin-top: 60px;
  }
}


@media screen and (min-width: 1001px) and (max-width: 1100px) {
  .InvernessIntroText {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.4);
    text-align: Justify;
    margin-bottom: 20px;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 370px;
    max-height: 370px;
    margin-top: 50px;
    width: 100%;
  }
  .AllohaIntroImgTwo{
    height: 300px;
    max-height: 300px;
    margin-top: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1367px) {
  .HerosectionButtonStyle {
    width: 80%;
    max-width: 800px;
    /* position: absolute; */
    top: 80%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }

  .ImgLogoMed {
    height: auto;
    width: 40%;
  }
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 450px;
    max-height: 600px;
    width: 100%;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1499px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 80%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 85%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1950px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 65%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 12%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 15%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2601px) and (max-width: 3200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 48%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 21%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3201px) and (max-width: 3400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 38%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 28%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3401px) and (max-width: 4000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3501px) and (max-width: 6000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 6001px) and (max-width: 10000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 18%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 40%;
    background: transparent;
    height: auto;
    border: none;
  }
}
@media (min-height: 450px) and (max-width: 600px) {
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 450px;
    width: 100%;
  }
  .AllohaIntroImgTwo {
    height: 100%;
    min-height: 450px;
    width: 100%;
  }
}

@media (min-height: 0px) and (max-width: 449px) {
  .MedhanshIntroImgTwo {
    height: 100%;
    min-height: 300px;
    width: 100%;
  }
  .AllohaIntroImgTwo {
    height: 100%;
    min-height: 400px;
    width: 100%;
  }
}

.img-filter {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  -webkit-filter: grayscale(0%);

          filter: grayscale(0%);
}

.img-zoom:hover {
  background-size: 110% 110%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}
.Rect1 {
  width: 98%;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/Rect1.eef092a2.jpg);
}

.Rect2 {
  width: 98%;
  height: 98%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/Rect6.f5bd6a73.jpg);
}

.Rect3 {
  margin-top: 5px;
  width: 98%;
  max-width: 98%;
  overflow: hidden;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/Rect3.72f2d2d4.png);
}

.Rect4 {
  margin-top: 5px;
  /* margin-right: 5px; */
  width: 98%;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/Rect4.27b1eea6.jpg);
}

.Rect5 {
  width: 98%;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/Rect5.579d4232.jpg);
}

.Rect6 {
  width: 98%;

  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/Rect6.f5bd6a73.jpg);
}

.Rect7 {
  width: 98%;
  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/Rect7.401ba97b.jpg);
}

.Rect8 {
  width: 98%;

  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/Rect8.6e2c12cc.jpg);
}

.Rect9 {
  width: 98%;

  height: 100%;
  min-height: 375px;
  max-height: 375px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/Rect9.a3eea480.png);
}

/* .OneImg {
  width: 98%;
  height: 100%;
  min-height: 500px;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect1.png);
}

.TwoImg {
  width: 98%;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/Medhaansh/Rect2.png);
} */
/* 
.Rect1:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect2:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect3:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect4:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect5:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.Rect6:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

@keyframes zoomin {
  from {
    background-size: auto;
  }
  to {
    background-size: 110%;
  }
} */
@media screen and (min-width: 0px) and (max-width: 600px) {
  .Rect1 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect2 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect3 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect4 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect5 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect6 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect7 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect8 {
    min-height: 200px;
    max-height: 200px;
  }

  .Rect9 {
    min-height: 200px;
    max-height: 200px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 800px) {
  .Rect1 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect2 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect3 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect4 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect5 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect6 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect7 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect8 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  .Rect9 {
    width: 98%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .Rect1 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect2 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect3 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect4 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect5 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect6 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect7 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect8 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
  .Rect9 {
    width: 98%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
  }
}

.AmentiseOne {
  background-color: #ffffff;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  height: 180px;
  width: 99%;
  position: relative;
  display: grid;
  place-items: center;
}
.AmentiseOneHover {
  position: absolute;
  transition: all 0.7s ease;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  overflow: hidden;
  width: 100%;
  display: grid;
  place-items: center;
}

.ssImageSectionStyles {
  background-image: url(/static/media/BroadImg.6fe21e0c.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  min-height: 590px;
  position: relative;
  -webkit-background-size: 2650px 1440px;
}
@media (max-width: 480px) {
  .ImageSectionStyles {
    background-image: url(/static/media/BroadImg.6fe21e0c.png);
    margin-top: -100vh;
    width: 100vw;
    background-size: cover;
    top: 0;
    height: 200px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 0;
  }
}

.parallax-section {
  position: relative;
  width: 100%;
  height: 700px;
}
.parallax-child-section {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
}

#frontlashID {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: transform;
  z-index: 1;
}
.fw-main-row {
  background-attachment: scroll;
  background-image: url(/static/media/BroadImg.6fe21e0c.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.am-img {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  opacity: 1;
  scale: 100%;
}

.AmentiseOne:hover .am-img {
  opacity: 0;
  scale: 0%;
}
.AmentiseOne:hover p {
  opacity: 1;
  scale: 100%;
}

.AmentiseOne p {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  opacity: 0;
  scale: 0%;
}

.HerosectionButtonStyle {
  width: 100%;
  max-width: 800px;
  position: absolute;
  top: 80%;
  right: 0%;
  background: transparent;
  height: auto;
  border: none;
}

.hoverImgs {
  width: 100%;
  overflow: hidden;
}

.hoverImgs img {
  width: 100%;
  transition: all 20s linear;
}

.hoverImgs img:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  cursor: pointer;
}

.heroImgAllo {
  background: url(/static/media/m_5.d1bda14e.jpg);
  height: 100vh;
  max-width: 100%;
  width: 100%;
  position: relative;
  width: "100%";
  max-height: 100vh;
  overflow: hidden;
  max-width: 100%;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  -webkit-animation: zoomhome 30s infinite alternate;
  animation: zoomhome 30s infinite alternate;
  background-position: center;
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
.imglogo {
  height: 40%;
  width: auto;
  margin-left: auto;
  margin-right: 0px;
  margin-top: -70px;
}
@media screen and (min-width: 768px) and (max-width: 1367px) {
  .HerosectionButtonStyle {
    width: 80%;
    max-width: 800px;
    top: 80%;
    bottom: 2%;
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}
@media (max-width: 768px) {
  .imglogo {
    height: 35%;
    margin-top: 50px;
    width: auto;
  }
}
@media (max-width: 480px) {
  .imglogo {
    height: 33%;
    width: auto;
    margin-top: 70px;
  }
  .heroImgAllo {
    background: url(/static/media/m_5.d1bda14e.jpg);
    height: 100vh;
    max-width: 100%;
    width: 100%;
    position: relative;
    width: "100%";
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    -webkit-animation: zoomhome 30s infinite alternate;
    animation: zoomhome 30s infinite alternate;
    background-position: center;
  }
}
@media (max-width: 380px) {
  .imglogo {
    height: 28%;
    width: auto;
  }
  .heroImgAllo {
    background: url(/static/media/m_5.d1bda14e.jpg);
    height: 100vh;
    max-width: 100%;
    width: 100%;
    position: relative;
    width: "100%";
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    -webkit-animation: zoomhome 30s infinite alternate;
    animation: zoomhome 30s infinite alternate;
    /* overflow-x: hidden; */
    background-position: center;
  }
}
@media (max-width: 200px) {
  .imglogo {
    height: 30%;
    width: auto;
  }
  .heroImgAllo {
    /* background-image: url(../../assets/Images/HomeCarouselImages/Inverness.png); */
    background: 
    /* linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), */ url(/static/media/m_5.d1bda14e.jpg);
    height: 100vh;
    max-width: 100%;
    width: 100%;
    position: relative;
    width: "100%";
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    -webkit-animation: zoomhome 30s infinite alternate;
    animation: zoomhome 30s infinite alternate;
    /* overflow-x: hidden; */
    background-position: center;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1499px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
  /* .imglogo{
    height: 250px;
  width: 400px;
  } */
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
  /* .imglogo{
    height: 250px;
  width: 400px;
  } */
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 80%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 85%;
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1950px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
  /* .imglogo {
    height: 400px;
    width: 600px;
    margin-top: -200px;
  } */
}

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;

    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }

}

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 65%;
    right: 12%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    right: 15%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2601px) and (max-width: 3200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 48%;
    right: 21%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3201px) and (max-width: 3400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 38%;
    right: 28%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3401px) and (max-width: 4000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3501px) and (max-width: 6000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 6001px) and (max-width: 10000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 18%;
    right: 40%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuramediumbt.ee64fb9d.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuralightbt.98d2f973.ttf) format("truetype");
  font-weight: normal;
}

.scrollable1 {
  /* background-color: red; */
  height: 100%;
  max-height: 550px;
  /* min-height: 90vh; */
  /* min-width: 300px; */
  overflow: auto;
  /* margin: 20px; */
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  /* padding: 0px; */
  /* scroll-snap-align: start; */
  /* scroll-padding-right: 80px; */
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.scrollable1::-webkit-scrollbar {
  display: none !important;
}
.scrollable1::-webkit-scrollbar {
  width: 0.4em !important;
}
::-webkit-scrollbar-track-piece {
  background: transparent;
}

.scrollable1::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.scrollable1::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0) !important;
  outline: 0px solid rgb(0, 0, 0) !important;
}

.QuotedText {
  /* font-family: Kalam, cursive; */
  font-family: Playfair Display;
  font-weight: 200;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}

.QuotedFooter {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-top: 20px;
  text-align: right;
  color: #4f4f4f;
}

.AllohaActiveButton5 {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
  outline: none !important ;
}

.AllohaActiveButton5:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.AllohaActiveButton5::before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

.AllohaActiveButton5::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.AllohaActiveButton5:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  cursor: pointer;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.UnActiveText5::before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}
.UnActiveText5:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}
.UnActiveText5 {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
  border: none;
  /* font-size: 5rem; */
}
.UnActiveText5::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.UnActiveText5:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.ActiveButton2 {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Futura Medium BT;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
}

.ActiveButton2:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.ActiveButton2::before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

.ActiveButton2::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
/* .UnActiveText2{
  font-size: 25px;
  width: auto;
  height: 80px;
  position: relative;
} */
.ActiveButton2:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  cursor: pointer;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.UnActiveText2:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}
.UnActiveText2 {
  height: 100px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
  background: rgb(219, 223, 225);
  border: none;
}
/* .UnActiveText2:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
} */

.AllohaActiveButton {
  height: 80px;
  width: auto;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
}

.AllohaActiveButton:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.AllohaActiveButton::before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

.AllohaActiveButton::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.AllohaActiveButton:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  cursor: pointer;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.UnActiveText::before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}
.UnActiveText:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}
.UnActiveText {
  width: auto;
  height: 80px;
  position: relative;
  /* font-size: 5rem; */
}
.UnActiveText::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.UnActiveText:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}
.senseImgOne {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 550px;
  max-height: 540px;
  background-size: cover;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: zoomhome 30s infinite alternate;
          animation: zoomhome 30s infinite alternate;
  background-image: url(/static/media/birds.1a5597b4.png);
}

.senseImgTwo {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 550px;
  max-height: 540px;
  background-size: cover;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: zoomhome 30s infinite alternate;
          animation: zoomhome 30s infinite alternate;
  background-image: url(/static/media/flowers.5d76d9ee.png);
}

.senseImgThree {
  width: 100%;
  height: 100%;
  min-height: 500px;
  position: relative;
  min-height: 550px;
  max-height: 540px;
  /* background-size: 100% 100%; */
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/sun.81648b57.png);
}

.bg-zoom-ani {
  background-size: cover;
  -webkit-animation: zoomhome2 5s ease alternate infinite;
          animation: zoomhome2 5s ease alternate infinite;
}

* {
  /* margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s linear; */
  /* font-family: tahoma; */
}

html {
  /* scroll-behavior: smooth; */
}

body {
  /* overflow-x: hidden; */
}

.sec {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  /* color: #777;
  background: #f9f9f9; */
  letter-spacing: 2px;
}

.navbar {
  position: fixed;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1000;
}

.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar ul li {
  width: 200px;
  position: relative;
  text-align: right;
}

.navbar ul li .dot {
  color: #fff;
  display: block;
  padding: 5px 0;
}

.navbar ul li .dot span {
  display: inline-block;
  background: #f44336;
  letter-spacing: 1px;
  padding: 10px 25px;
  margin-right: 30px;
  border-radius: 3px;
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
  opacity: 0;
}

.navbar ul li:hover .dot span {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
  opacity: 1;
}

.navbar ul li .dot span::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(7px, -50%);
          transform: translate(7px, -50%);
  border-left: 7px solid #f44336;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  display: block;
}

.navbar ul li .dot::before,
.navbar ul li .dot::after {
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 50%;
  display: block;
  transition: 0.2s ease-out;
}

.navbar ul li .dot::before {
  height: 5px;
  width: 5px;
  border: 2px solid #333;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.navbar ul li .dot.active::before,
.navbar ul li:hover .dot::before {
  border-color: #f44336;
  background: #f44336;
}
.navbar ul li .dot::after {
  height: 15px;
  width: 15px;
  border: 2px solid #f44336;
  right: -5px;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
}

.navbar ul li .dot.active::after,
.navbar ul li:hover .dot::after {
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1);
}

@keyframes zoomhome2 {
  from {
    background-size: 100% 100%;
  }
  to {
    background-size: 110% 110%;
  }
}
@-webkit-keyframes zoomhome2 {
  from {
    background-size: 100% 100%;
  }
  to {
    background-size: 110% 110%;
  }
}

.senseImgFour {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 550px;
  max-height: 540px;
  background-size: 100% 100%;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/seenew.4ca7cbc7.jpg);
  /* animation: zoomhome 30s infinite alternate; */
}

.senseImgFive {
  width: 100%;
  position: relative;

  height: 100%;
  min-height: 550px;
  max-height: 540px;
  background-size: 100% 100%;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/orange.2ed7df7a.png);
}
.noSenseImg {
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 550px;
  max-height: 540px;
  background-size: 100% 100%;
  margin-top: 1px;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: red; */
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.noSenseImg h3 {
  text-align: center;
  color: black;
  font-size: 45px;
}
.noSenseImg img {
  background: black;
  margin-left: auto;
  margin-right: auto;
}
.noSenseImg .AllohaSenseIcon {
  text-align: center;
}
.senseImgOnePhone {
  width: 98%;
  height: 100%;
  position: relative;
  min-height: 345px;
  max-height: 345px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  /* animation: zoomhome 30s infinite alternate; */
  background-image: url(/static/media/birds.1a5597b4.png);
}

.senseImgTwoPhone {
  width: 98%;
  height: 100%;
  position: relative;
  min-height: 345px;
  max-height: 345px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  /* animation: zoomhome 30s infinite alternate; */
  background-image: url(/static/media/flowers.5d76d9ee.png);
}

.senseImgThreePhone {
  width: 98%;
  height: 100%;
  /* min-height: 500px; */
  position: relative;
  min-height: 345px;
  max-height: 345px;
  /* background-size: 100% 100%; */
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/sun.81648b57.png);
}
.senseImgFourPhone {
  width: 98%;
  height: 100%;
  position: relative;
  min-height: 345px;
  max-height: 345px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  /* animation: zoomhome 30s infinite alternate; */
  background-image: url(/static/media/seenew.4ca7cbc7.jpg);
}

.senseImgFivePhone {
  width: 98%;
  height: 100%;
  position: relative;
  min-height: 345px;
  max-height: 345px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  /* animation: zoomhome 30s infinite alternate; */
  background-image: url(/static/media/orange.2ed7df7a.png);
}
.AllohaOverLayPhone {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 180.13px;
  height: 150px;
  /* bottom: 8%;
  right: 2%; */
  /* top: 28%; */
  top: 8%;
  left: 6%;
  padding: 6px;
  text-align: start;
  /* transform: translate(-0%, -50%); */
}
.AllohaOverLayPhone .AllohaSenseIconPhone {
  text-align: start;
  margin-top: 10px;
}
.AllohaOverLayPhone .AllohaSenseIconPhone img {
  width: 25px;
  height: 25px;
  align-items: left;
}
.AllohaOverLayPhone h3 {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 13px;
}
.AllohaOverLayPhone p {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 13px;
}
.AllohaOverLay .AllohaSenseIcon {
  text-align: start;
  margin-top: 25px;
}

.AllohaOverLay .AllohaSenseIcon img {
  width: 50px;
  height: 50px;
  align-items: left;
}

.AllohaOverLay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 260.13px;
  height: 220px;
  top: 10%;
  left: 3%;
  padding: 12px;
  text-align: start;
  /* font-family: Kalam, cursive; */
  font-family: Playfair Display;
  -webkit-animation: AllohaOverLayani 2s 0s ease alternate;
          animation: AllohaOverLayani 2s 0s ease alternate;
}

@-webkit-keyframes AllohaOverLayani {
  from {
    bottom: -60%;
    opacity: 0;
    visibility: hidden;
  }

  to {
    bottom: 8%;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes AllohaOverLayani {
  from {
    bottom: -60%;
    opacity: 0;
    visibility: hidden;
  }

  to {
    bottom: 8%;
    opacity: 1;
    visibility: visible;
  }
}

.AllohaOverLay h3 {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 23px;
}
.AllohaOverLay p {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  margin-top: 22px;
}
.line-break {
  color: rgb(194, 184, 184);
}
.img-zoom-ani {
  -webkit-animation: zoomhome 30s infinite alternate;
          animation: zoomhome 30s infinite alternate;
}

.AllohaActiveButton {
  font-size: 25px;
}
.UnActiveText {
  font-size: 25px;
}
.UnActiveTextCaroOfAl {
  height: 30px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
  border: none;
}

.slick-btnAll .slick-active {
  /* background-color:red; */
  /* background: rgb(224, 223, 223); */
  opacity: 1;
  color: black;
}
.slick-btnAll .slick-active .UnActiveTextCaroOfAl {
  opacity: 1;
  /* color:red; */
}

.slick-btnAll.AllohaBtns {
  display: flex !important;
  grid-gap: 3px;
  gap: 3px;
  -webkit-margin-before: auto !important;
          margin-block-start: auto !important;
  -webkit-margin-after: auto !important;
          margin-block-end: auto !important;
  -webkit-padding-start: 0px !important;
          padding-inline-start: 0px !important;
  margin-left: auto;
  margin-right: auto;

  /* margin-top: 7px; */
}
.slick-btnAll.AllohaBtns li {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
/* h1::before {  
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  
  h1:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  h1::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    inset: 0 0 0 0;
    background: hsl(200 100% 80%);
    z-index: -1;
    transition: transform .3s ease;
  }
  
  h1 {
    position: relative;
    font-size: 5rem;
  } */
@media only screen and (max-width: 900px) {
  .QuotedText {
    font-size: 20px;
    line-height: 30px;
  }
  .AllohaActiveButton {
    font-size: 20px;
  }
  .AllohaUnActiveText {
    font-size: 20px;
  }
  /* .senseImgOne {
    width: 80%;
  height: 80%;
  position: relative;
  min-height: 240px;
  max-height: 6240px;
  background-size: cover;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomhome 30s infinite alternate;
  } */
  .scrollable1 {
    /* background-color: red; */
    height: 375px;
    min-width: 375px;
    overflow: auto;
    /* margin: 20px; */
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    padding: 0px;
    overflow-y: scroll;

    /* margin-top: "30px"; */
  }
  .senseImgOne {
    min-height: 375px;
    max-height: 375px;
  }
  .senseImgTwo {
    min-height: 375px;
    max-height: 375px;
  }
  .senseImgThree {
    min-height: 375px;
    max-height: 375px;
  }
  .senseImgFour {
    min-height: 375px;
    max-height: 375px;
  }
  .senseImgFive {
    min-height: 375px;
    max-height: 375px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1300px) {
  .QuotedText {
    font-size: 25px;
    line-height: 35px;
  }
  .scrollable1 {
    /* background-color: red; */
    height: 100%;
    max-height: 400px;
    min-width: 300px;
    overflow: auto;
    /* margin: 20px; */
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    padding: 0px;
    overflow-y: scroll;

    /* margin-top: "30px"; */
  }
}
@media (max-width: 767px) {
  .AllohaOverLay {
    width: 500.13px;
    height: 320px;
  }
}
@media (max-width: 600px) {
  .senseImgTwoPhone {
    min-height: 330px;
    max-height: 330px;
  }
  .senseImgOnePhone {
    min-height: 330px;
    max-height: 330px;
  }
  .senseImgThreePhone {
    min-height: 330px;
    max-height: 330px;
  }
  .senseImgFourPhone {
    min-height: 330px;
    max-height: 330px;
  }
  .senseImgFivePhone {
    min-height: 330px;
    max-height: 330px;
  }
  .scrollable1 {
    height: 100%;
    min-height: 330px;
    max-height: 330px;
    max-width: 330px;

    /* margin-top: "30px"; */
  }
}

@media (max-width: 480px) {
  .AllohaOverLay {
    top: 40%;
    width: 320px;
    height: 300px;
  }
  .AllohaOverLay h3 {
    font-family: "Playfair Display";
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 16px;
  }
  .AllohaOverLay p {
    font-family: "Playfair Display";
    font-weight: 400;
    font-size: 10px;
    color: #ffffff;
    margin-top: 16px;
  }
  .AllohaActiveButton {
    text-align: left;
  }
  .scrollable1 {
    height: 100%;
    min-height: 320px;
    max-height: 320px;
    max-width: 100%;

    /* margin-top: "30px"; */
  }
  .senseImgOne {
    min-height: 320px;
    max-height: 320px;
  }
  .senseImgTwo {
    min-height: 320px;
    max-height: 320px;
  }
  .senseImgThree {
    min-height: 320px;
    max-height: 320px;
  }
  .senseImgFour {
    min-height: 320px;
    max-height: 320px;
  }
  .senseImgFive {
    min-height: 320px;
    max-height: 320px;
  }
}
@media (max-width: 420px) {
  .AllohaOverLay {
    top: 40%;
    width: 260px;
    height: 260px;
  }
  .AllohaOverLay h3 {
    font-size: 10px;
    margin-bottom: 14px;
  }
  .AllohaOverLay p {
    font-size: 8px;
    margin-top: 14px;
  }
  .AllohaActiveButton {
    text-align: left;
  }
  .UnActiveTextCaroOfAl {
    font-size: 15px;
  }

  /* .scrollable1 {
    background-color: red;
    height: 100%;
    max-width: 325px;
    overflow: auto;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    padding: 20px;
    overflow-y: scroll;

    margin-top: "30px";
  } */

  /* .senseImgFive {
    width: 40%;
  }
  .senseImgFour {
    width: 40%;
  }
  .senseImgThree {
    width: 40%;
  }
  .senseImgTwo {
    width: 40%;
  }
  .senseImgOne {
    width: 40%;
  }
  .scrollable1 {
    width: 50%;
  } */
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

/* .OneImg {
  width: 98%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/AllohaHills/AllohaHills1.png);
  transform: scale(1.0);
  overflow: hidden;
} */
/* .OneImg img {
  width:100%;
  transition: all 20s linear;
} */
/* .OneImg:hover {
  animation: zoomhome 20s linear;
  transform: scale(1.5);
  cursor: pointer;
} */
.TwoImg {
  width: 98%;
  margin-top: 5px;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;

  background-repeat: no-repeat;
  /* background-image: url("../../assets/Images/AllohaHills/g/m\ \(1\).j"); */
  /* animation: zoomhome 20s linear; */
}

.ThreeImg {
  width: 100%;
  margin-top: 5px;
  max-width: 98%;
  overflow: hidden;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/AllohaHills3.b1bd4163.png);
}

.FourImg {
  width: 97%;
  margin-top: 5px;
  overflow: hidden;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/AllohaHills4.72f2d2d4.png);
}

.FiveImg {
  width: 98%;

  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/AllohaHills5.6d6864f8.png);
}

.SixImg {
  width: 98%;

  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/AllohaHills6.a1c4e032.png);
}

.SevenImg {
  width: 98%;

  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/AllohaHills7.45f3cdfa.png);
}

.EightImg {
  width: 98%;
  min-height: 500px;
  height: 100%;
  max-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/AllohaHills8.e15b5f2e.png);
}

/* .OneImg:hover {
  transition: transform 0.5s;
  animation: zoomhome 20s ease-in-out;
} */
/* 
.TwoImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}


.ThreeImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.FourImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.FiveImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.SixImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.SevenImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
}

.EightImg:hover {
  background-size: cover;
  cursor: pointer;
  transform: scale(1.01);
  transition: transform 0.5s;
  animation-delay: ease-in;
} */
.img-filter {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  -webkit-filter: grayscale(0%);

  filter: grayscale(0%);
}

.img-zoom:hover {
  background-size: 110% 110%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}

.AlOneImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  margin-top: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/static/media/m (1).cfe8cf0a.jpg");
}

.AlTwoImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/static/media/m (2).88d035b8.jpg");
}

.AlThreeImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/static/media/m (3).c1b43313.jpg");
}

.AlFourImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/static/media/m (4).73584a5e.jpg");
}

.AlFiveImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/m_5.d1bda14e.jpg);
}

.AlSixImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/static/media/m (6).2ce6b779.jpg");
}

.AlSevenImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/static/media/m (7).0c554165.jpg");
}

.AlEightImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/static/media/m (8).17052d63.jpg");
}

.AlNineImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/static/media/m (9).738e7727.jpg");
}
.AlTenImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/AllohaHills2.b52f6913.png);
}
.AlElevenImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/AllohaHills3.b1bd4163.png);
}
.AlTweleveImg {
  width: 98%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/AllohaHills4.72f2d2d4.png);
}

@-webkit-keyframes zoomhome {
  from {
    -webkit-transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.1, 1.1);
  }
}

@keyframes zoomhome {
  from {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.1, 1.1);
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .AlOneImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlTwoImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlThreeImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlFourImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlFiveImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlSixImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlSevenImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlEightImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlNineImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlTenImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlElevenImg {
    min-height: 280px;
    max-height: 280px;
  }
  .AlTweleveImg {
    min-height: 280px;
    max-height: 280px;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .AlOneImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlTwoImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlThreeImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlFourImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlFiveImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlSixImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlSevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlEightImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlNineImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlTenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlElevenImg {
    min-height: 250px;
    max-height: 250px;
  }
  .AlTweleveImg {
    min-height: 250px;
    max-height: 250px;
  }
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuramediumbt.ee64fb9d.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url(/static/media/futuralightbt.98d2f973.ttf) format("truetype");
  font-weight: normal;
}

.ActiveButton {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
}

.UnActiveText:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}
.UnActiveText {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;

  border: none;
}
/* @media only screen and (max-width: 600px) {
  .UnActiveText {
    text-align: left;
  }
} */

@media (max-width: 480px) {
  .ActiveButton {
    text-align: center !important;
    align-items: center !important;
  }
  .UnActiveText {
    text-align: center !important;
    align-items: center !important;
  }
}

@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("/static/media/futura medium condensed bt.bf7355ba.ttf")
      format("truetype");
  font-weight: normal;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  width: 120%;
  border: 0.5px solid #868686;
}

.MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.css-1xc9rp1-MuiInputBase-root-MuiInput-root {
  border: 1px solid #f1e9e9;
}

textarea {
  font: inherit;
  resize: "none";
  letter-spacing: inherit;
  color: currentColor;
  width: 98%;
}

.InputBoxstyle {
  width: "100%";
  max-width: "235px";
  margin-left: "auto";
  margin-right: "auto";
  height: "50px";
  border: "1px solid #F4F4F4";
  border-radius: " 0px";
  padding-left: "10px";
  margin-top: "15px";
  font-style: "normal";
  font-weight: 400;
  font-size: "16px";
  line-height: "25px";
  text-align: "left";
  letter-spacing: "0.05em";
  color: "#868686";
}

.contact-us-bg-image {
  width: 100%;
  height: 380px;
  background: url(/static/media/Rectangle-1.ec14e90c.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 1500px) {
  .LastInputBoxStyle {
    width: 98%;
  }
}

.InputBoxStyle1 {
  width: 92%;
  max-width: 250px;
  margin-top: 18px;
  height: 55px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  align-items: left;
  -webkit-appearance: none;
  appearance: none;
}
.InputBoxStyle2 {
  width: 92%;
  max-width: 250px;
  margin-top: 18px;
  height: 55px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  align-items: right;
  -webkit-appearance: none;
  appearance: none;
}

.ContactInputMsgBox {
  width: 95%;
  max-width: 530px;
  margin-top: 18px;
  height: 55px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  -webkit-appearance: none;
  appearance: none;
}

.LastInputBoxStyle {
  width: 93%;
  max-width: 550px;
  margin-top: 18px;
  height: 160px;
  border-top: none;
  border: 1px solid #f4f4f4 !important;
  border-radius: 0px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #868686;
  -webkit-appearance: none;
  appearance: none;
}

@media only screen and (min-width: 0px) and (max-width: 400px) {
  .ContactInputMsgBox {
    width: 99%;
    max-width: 340px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle1 {
    width: 100%;
    max-width: 340px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 100%;
    max-width: 340px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 94%;
    max-width: 340px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
  .ContactInputMsgBox {
    width: 99%;
    max-width: 350px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle1 {
    width: 100%;
    max-width: 350px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 100%;
    max-width: 350px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 94%;
    max-width: 350px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .ContactInputMsgBox {
    width: 99%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle1 {
    width: 100%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 100%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 94%;
    max-width: 400px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .InputBoxStyle1 {
    width: 85%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 85%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  /* .LastInputBoxStyle {
    width: 93%;
    max-width: 400px;
    margin-top: 18px;
    height: 160px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  } */
}

@media screen and (min-width: 1201px) and (max-width: 2000px) {
  .InputBoxStyle1 {
    width: 90%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .InputBoxStyle2 {
    width: 90%;
    max-width: 400px;
    margin-top: 18px;
    height: 55px;
    border: 1px solid #f4f4f4 !important;
    border-radius: 0px;
    padding-left: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #868686;
  }
  .LastInputBoxStyle {
    width: 95%;
  }
}

.slick-dots li.slick-active button:before {
  height: 2px;
  width: 10px;
  background-color: black;
  color: black;
  border-radius: 0px;
}

.slick-dots li button:before {
  height: 2px;
  width: 10px;
  background-color: #f9f9f9;
  font-size: 0px;
}

.slick-dots {
  list-style-type: square;
}

.hoverImg {
  position: relative;
}

.caroImg {
  height: 550px;
  display: block;
  overflow: hidden;
  width: 97%;
  margin-left: 0;
  margin-right: auto;
}

.layer {
  background: transparent;
  height: 100%;
  width: 97%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 2s;
}

.layer:hover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 140%);
  transition: 7s;
}

.layer h5 {
  width: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 200;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.layer h3 {
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  left: 55%;
  opacity: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
}

.layer h3 .span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
}

.layer h6 {
  width: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.layer h5 {
  bottom: 19%;
  opacity: 0;
}

.layer h3 {
  bottom: 12%;
  opacity: 0;
}

.layer h6 {
  bottom: 1%;
  opacity: 0;
}
.layer:hover h5 {
  bottom: 19%;
  opacity: 1;
}

.layer:hover h3 {
  bottom: 12%;
  opacity: 1;
}

.layer:hover h6 {
  bottom: 1%;
  opacity: 1;
}

.layer-one-pic {
  background: transparent;
  height: 100%;
  width: 97%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 2s;
}

.layer-one-pic:hover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 140%);
  transition: 7s;
}

.layer-one-pic h5 {
  width: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 15px;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-style: normal;
  font-family: "Playfair Display";
  font-weight: 200;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 8px;
  text-transform: uppercase;
  bottom: 19%;
  opacity: 0;
}

.layer-one-pic h3 {
  width: auto;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  left: 55%;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-style: normal;
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  bottom: 12%;
  opacity: 0;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.layer-one-pic h3 .span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
}

.layer-one-pic h6 {
  width: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
  transition: 2s;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  bottom: 1%;
  opacity: 0;
  text-align: center;
}

.layer-one-pic:hover h5 {
  bottom: 19%;
  opacity: 1;
}

.layer-one-pic:hover h3 {
  bottom: 12%;
  opacity: 1;
}

.layer-one-pic:hover h6 {
  bottom: 1%;
  opacity: 1;
}

@media (max-width: 480px) {
  .layer-one-pic {
    width: 97%;
  }

  .caroImg {
    height: 300px;
    width: 97.7%;
    margin-left: 0.3%;
    margin-right: auto;
  }

  .layer-one-pic {
    left: 0%;
    right: 0;
  }
}
@media (max-width: 320px) {
  .layer-one-pic {
    left: 5.5%;
  }
  .layer-one-pic {
    width: 90%;
  }
}

.carouselProjectImageHeight {
  height: 550px;
  width: 97%;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .carouselProjectImageHeight {
    height: 270px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 960px) {
  .carouselProjectImageHeight {
    height: 370px;
  }
}

