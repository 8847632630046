@font-face {
  font-family: "Futura Medium BT";
  src: local("Futura Medium BT"),
    url("../../assets/Fonts/futuramediumbt.ttf") format("truetype");
  font-weight: normal;
}

.InvernessIntroText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #808186;
  text-align: Justify;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.heroImgInver {
  /* background-image: url(../../assets/Images/HomeCarouselImages/Inverness.png); */
  background:
   /* linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), */ url(../../assets/Images/HomeImages/14_Wood_house_SectorB.jpg);

  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  position: relative;
  z-index: 0;

  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  animation: zoomhome 30s infinite alternate;
  background-position: center;
}
.MuiGrid-root.MuiGrid-container.css-1k888q6-MuiGrid-root {
  max-width: 357px;
}

.InvernessCaroImgOne {
  background-image: url(../../assets/Images/Inverness/InvernessB.png);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.InvernessCaroImgTwo {
  background-image: url(../../assets/Images/Inverness/Inverness/InImgTwo.jpeg);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.InvernessCaroImgThree {
  background-image: url(../../assets/Images/Inverness/Inverness/InImgThree.jpeg);
  width: 95%;
  height: 100%;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.InvernessCaroImgFour {
  background-image: url(../../assets/Images/Inverness/Inverness/InImgSeven.jpeg);
  width: 98%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 500px; */
  /* max-height: 100%; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.img-zoom:hover {
  background-size: 110% 110%;
  cursor: pointer;
}

.img-zoom {
  transition: background-size 2s ease-in;
  -moz-transition: background-size 2s ease-in;
  -ms-transition: background-size 2s ease-in;
  -o-transition: background-size 2s ease-in;
  -webkit-transition: background-size 2s ease-in;
}

.img-filter {
  filter: grayscale(100%);
}
.img-filter:hover {
  transition: 1s linear;

  filter: grayscale(0%);
}

.OurPartnerText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: center;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.AmentiseText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #bababa;
  text-align: Justify;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.visionmissiontext {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #979797;
  text-align: Justify;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.InvernessIntroText,
.InvernessIntroText span {
  animation-duration: 1s;
  animation-fill-mode: both;
  font-family: "Playfair Display";
}

.OurPartnerText,
.OurPartnerText span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.OurPartnerText span {
  display: inline-block;
}

.InvernessIntroText span {
  display: inline-block;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}

.LinkStyleText {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #5392bf;
  text-align: Justify;
  animation-duration: 1s;
  animation-fill-mode: both;
  text-align: center;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
.ActiveButton1 {
  height: 80px;
  width: 100%;
  background-color: transparent;
  font-family: Playfair Display;
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #333333;
  border: none;
}

.ActiveButton1:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.ActiveButton1::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.ActiveButton1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: rgb(219, 223, 225);
  z-index: -1;
  transition: transform 0.3s ease;
}
.UnActiveText1 {
  font-size: 25px;
  width: auto;
  height: 80px;
  position: relative;
}
.ActiveButton1:hover::before {
  transform: scaleX(1);
  cursor: pointer;
  transform-origin: bottom left;
}

.UnActiveText1:hover {
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}

.InvernessIntroTextTitle {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: #252733;
}
@-webkit-keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes zoomhome {
  0% {
    -webkit-transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
.invernessLogo {
  height: auto;
  min-width: 300px;
  width: 30%;
  margin-left: auto;
  margin-right: 0px;
  margin-top: 2px;
  margin-bottom: 20px;
}
@media screen and (min-width: 381px) and (max-width: 480px) {
  .invernessLogo {
    height: auto;
    /* background-color: blue; */
    width: 55%;
    margin-top: 50px;
  }

  /* margin-left:100px; */
  /* margin-left:auto; */
  /* margin-right: auto; */
  /* position: absolute; */
  /* right:40%; */
  /* .audioSetting{
    margin-top: 2px;
    margin-left: "5px";
  } */
  .heroImgInver {
    background:
     /* linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), */ url(../../assets/Images/HomeImages/14_Wood_house_SectorB.jpg);
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 0;

    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    animation: zoomhome 30s infinite alternate;
    background-position: center;
  }
}
@media screen and (min-width: 0px) and (max-width: 380px) {
  .invernessLogo {
    height: auto;
    width: 30%;
    /* background-color: red; */
    margin-top: 50px;
  }
  /* position: absolute; */
  /* right:35%; */
  /* margin-left:50px;
    margin-right: auto */
  /* .audioSetting{
    margin-top: 3px;
    margin-left:"5px"
   
  } */
  .heroImgInver {
    background: 
    /* linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), */ url(../../assets/Images/HomeImages/14_Wood_house_SectorB.jpg);
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 0;

    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    animation: zoomhome 30s infinite alternate;
    background-position: center;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .InvernessIntroText {
    font-size: 14px;
    line-height: 25px;
  }
  .visionmissiontext {
    font-size: 16px;
    line-height: 25px;
  }
  .OurPartnerText {
    font-size: 14px;
    line-height: 25px;
  }
  .InvernessCaroImgOne {
    height: 380px;
  }
  /* .invernessLogo {
    height: auto;
    width: 45%;
    margin-top: 50px;
  } */
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .InvernessIntroText {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1367px) {
  .HerosectionButtonStyle {
    width: 80%;
    max-width: 800px;
    /* position: absolute; */
    top: 80%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
  .InvernessCaroImgOne {
    height: 500px;
  }
  .invernessLogo {
    height: auto;
    width: 45%;
    margin-top: 50px;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1499px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    /* position: absolute; */
    top: 90%;
    bottom: 2%;
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 90%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 80%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1850px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 85%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 2%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1950px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 1951px) and (max-width: 2000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2050px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2051px) and (max-width: 2100px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 70%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2150px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 65%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 12%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2151px) and (max-width: 2200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 8%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 60%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 15%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2401px) and (max-width: 2600px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 55%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 17%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 2601px) and (max-width: 3200px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 48%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 21%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3201px) and (max-width: 3400px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 38%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 28%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3401px) and (max-width: 4000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 3501px) and (max-width: 6000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 26%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 36%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media screen and (min-width: 6001px) and (max-width: 10000px) {
  .HerosectionButtonStyle {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 18%;
    /* bottom: 2%; */
    /* transform: translateY(-50%); */
    right: 40%;
    background: transparent;
    height: auto;
    border: none;
  }
}

@media (min-height: 450px) and (max-width: 900px) {
  .InvernessCaroImgThree {
    height: 100%;
    min-height: 450px;
    width: 100%;
  }
}

@media (min-width: 901px) and (max-width: 1100px) {
  .InvernessCaroImgThree {
    height: 100%;
    min-height: 450px;
    max-height: 450px;
    width: 100%;
  }
}

@media (min-height: 0px) and (max-width: 449px) {
  .InvernessCaroImgThree {
    height: 100%;
    min-height: 300px;
    width: 100%;
  }
}
